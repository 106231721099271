/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form } from 'react-bootstrap';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCircle from 'react-icons/lib/fa/circle';

// eslint-disable-next-line css-modules/no-unused-class
import s from './ProductPage.scss';
// eslint-disable-next-line css-modules/no-unused-class
import ProductListStyle from '../ProductList/ProductList.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import CategoryListsWrapper from '../LocalStorage/CategoryListsWrapper';
import CardRecommendationWidget from '../RecommenderWidget';
import {
  findBestUpSellingProductsQuery,
  increaseClickCountMutation,
  increaseHitrateMutation,
  renderStars,
  renderInactiveStars,
  getRuleSetQuery,
} from '../ProductList';
import Loading from '../Loading';
import ImagesList from './ImagesList';
// import UpsellingProductsList from '../ProductList/UpsellingProductsList';
import ProductComparisionTable from './ProductComparisionTable';
import Breadcrumbs from '../Breadcrumbs';
// import DanubeTags from '../DanubeTags';
import Error from '../Error';
import BuyModal from './BuyModal';

const CARD_MIN_WIDTH = 320;

const productDetailsQuery = gql`
  query productDetails($productId: Int!, $category: String!) {
    productDetails(productId: $productId, category: $category) {
      product {
        id
        product
        image_thumb
        description {
          prop
          value
        }
        best_price
        ppu
        offer_count
        rating_comments
        rating_count
        rating_percent
        rating_stars
        timestamp
        hitrate
      }
      danubeProduct {
        fields {
          field
          value
        }
      }
      productDetails {
        gzhid
        product
        listed_since
        description {
          prop
          value
        }
        description_teaser
        category {
          label
        }
        images
        popularity
        gtin
        urls {
          overview
          offers
          pricehist
          rate
          reviews
        }
        offer_count
        offers {
          avl {
            best_code
          }
          price {
            date
            legal
            url
            value
          }
          shop {
            name
            logo
            prod_desc
            ratings {
              count
              rating
              valid
            }
            url
          }
        }
        prices {
          avg
          best
        }
      }
    }
  }
`;

class ProductPage extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        category: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    categoryLists: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    addItemToCategoryLists: PropTypes.func.isRequired,
    removeItemFromCategoryLists: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingRuleSet: true,
      ruleSet: null,
      loadingProductData: false,
      productDetailsData: null,
      loadingUpsellingProducts: false,
      upsellingProducts: [],
      upsellingReferenceProduct: null,
      columnKeys: [],
      columnScores: [],
      errors: [],
      expanded: false,
      hoveredProductIndex: -1, // eslint-disable-line react/no-unused-state
      hoveredStar: -1, // eslint-disable-line react/no-unused-state
      cardsPerPage: 5,
      manufacturerFilterActive: false,
      columnBoost: {
        columns: [],
      },
      processBuying: false,
      showBuyModal: false,
    };

    this.upsellingListRef = React.createRef();

    this.onResize = this.onResize.bind(this);
    this.loadRuleSet = this.loadRuleSet.bind(this);
    this.loadProductData = this.loadProductData.bind(this);
    this.findBestUpSellingProduct = this.findBestUpSellingProduct.bind(this);
    this.handleUpSellingClicked = this.handleUpSellingClicked.bind(this);
    this.handleBuyClicked = this.handleBuyClicked.bind(this);
    this.handleAttributeClick = this.handleAttributeClick.bind(this);
    this.renderOfferHeaders = this.renderOfferHeaders.bind(this);
    this.renderOfferLine = this.renderOfferLine.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.loadRuleSet();
  }

  onResize() {
    if (this.upsellingListRef && this.upsellingListRef.current) {
      const width = this.upsellingListRef.current.clientWidth - 140;
      const cardsPerPage = Math.floor(width / CARD_MIN_WIDTH);
      this.setState({ cardsPerPage });
    }
  }

  async loadRuleSet() {
    try {
      this.setState({ loadingRuleSet: true });

      const result = await this.context.client.query({
        query: getRuleSetQuery,
        variables: {
          category: this.props.match.params.category,
        },
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getRuleSet) {
        if (result.data.getRuleSet.errors) {
          this.setState({
            loadingRuleSet: false,
            errors: [result.data.getRuleSet.errors],
          });
        } else {
          this.setState(
            {
              loadingRuleSet: false,
              ruleSet: JSON.parse(result.data.getRuleSet.data),
            },
            () => {
              if (this.state.ruleSet != null) {
                this.loadProductData();
              }
            },
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingRuleSet: false,
        errors: [e.message],
      });
    }
  }

  async loadProductData() {
    try {
      this.setState({ loadingProductData: true });

      const result = await this.context.client.query({
        query: productDetailsQuery,
        variables: {
          productId: this.props.match.params.id,
          category: this.props.match.params.category,
        },
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.productDetails) {
        if (result.data.productDetails.errors) {
          this.setState({
            loadingProductData: false,
            errors: [result.data.productDetails.errors],
          });
        } else {
          if (result.data.productDetails.product) {
            delete result.data.productDetails.product.__typename;
            if (result.data.productDetails.product.description) {
              result.data.productDetails.product.description.forEach(d => {
                // eslint-disable-next-line no-param-reassign
                delete d.__typename;
              });
            }
          }

          this.setState(
            {
              loadingProductData: false,
              errors: [],
              productDetailsData: result.data.productDetails,
            },
            () => {
              this.onResize();
              this.findBestUpSellingProduct();
            },
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingProductData: false,
        errors: [e.message],
      });
    }
  }

  async findBestUpSellingProduct() {
    this.setState({
      loadingUpsellingProducts: true,
      upsellingProducts: [],
      upsellingReferenceProduct: null,
    });

    const manufacturerField = this.state.productDetailsData.danubeProduct.fields.find(
      f => f.field === 'manufacturer',
    );

    const result = await this.context.client.query({
      query: findBestUpSellingProductsQuery,
      variables: {
        referenceProductId: this.state.productDetailsData.product.id,
        category: this.props.match.params.category,
        filter: {
          manufacturer: this.state.manufacturerFilterActive
            ? manufacturerField.value
            : null,
        },
        columnBoost: this.state.columnBoost,
        stripUnimportantFields: false,
        stripColumnScores: false,
      },
    });

    if (result && result.data && result.data.findBestUpSellingProducts) {
      this.setState({
        loadingUpsellingProducts: false,
        upsellingProducts: result.data.findBestUpSellingProducts.products,
        upsellingReferenceProduct:
          result.data.findBestUpSellingProducts.referenceProduct,
        columnKeys: result.data.findBestUpSellingProducts.columnKeys,
        columnScores: result.data.findBestUpSellingProducts.columnScores,
      });
    } else {
      this.setState({
        loadingUpsellingProducts: false,
        upsellingProducts: [],
        upsellingReferenceProduct: null,
        columnKeys: [],
        columnScores: [],
      });
    }
  }

  handleUpSellingClicked(product, upsellingProduct) {
    this.context.client.mutate({
      mutation: increaseClickCountMutation,
      variables: {
        referenceProductId: product.id,
        upsellingProductId: upsellingProduct.id,
      },
    });
  }

  async handleBuyClicked(product) {
    const refId = parseInt(this.props.location.search.split('refId=')[1], 10);

    // if products was suggested (i.e. if there is a reference product) increase hitcount on buy
    if (refId != null) {
      this.setState({ processBuying: true });

      try {
        await this.context.client.mutate({
          mutation: increaseHitrateMutation,
          variables: {
            referenceProductId: refId,
            upsellingProductId: product.id,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }

    this.setState({ processBuying: false, showBuyModal: true });
  }

  handleAttributeClick({ attribute }) {
    if (this.state.loadingUpsellingProducts) {
      return;
    }

    const { columnBoost } = this.state;
    const index = columnBoost.columns.indexOf(attribute);
    if (index !== -1) {
      columnBoost.columns.splice(index, 1);
    } else {
      columnBoost.columns.push(attribute);
    }
    this.setState({ columnBoost }, () => {
      this.findBestUpSellingProduct();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderOfferHeaders() {
    return (
      <div className={s.tableHeader}>
        <div className={s.priceColumn}>Preis exkl. Versand</div>
        <div className={s.merchantColumn}>Anbieter</div>
        <div className={s.ratingColumn}>Händlerbewertung</div>
        <div className={s.availabilityColumn}>Verfügbarkeit</div>
        <div className={s.descriptionColumn}>
          Produktbezeichnung des Händlers
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderOfferLine(offer, index) {
    return (
      <div
        key={`offer-${index}`}
        className={`${s.tableLine} ${index % 2 === 0 ? s.evenRow : s.oddRow}`}
      >
        <div className={s.priceColumn}>€ {offer.price.value}</div>
        <div className={s.merchantColumn}>
          {offer &&
            offer.shop && [
              <div key="logo">
                <img src={offer.shop.logo} alt="" />
              </div>,
              <div key="name">{offer.shop.name}</div>,
            ]}
        </div>
        <div className={s.ratingColumn}>
          {offer &&
            offer.shop &&
            offer.shop.ratings && [
              <div key="ratingLine" className={s.ratingLine}>
                <div className={s.starsWrapper}>
                  {offer.shop.ratings.rating
                    ? renderStars(offer.shop.ratings.rating, 5)
                    : renderInactiveStars(5)}
                </div>
                <span>{offer.shop.ratings.rating}</span>
              </div>,
              <div key="count">
                <span>
                  {offer.shop.ratings.count
                    ? ` ${offer.shop.ratings.count} Bewertungen`
                    : ' 0 Bewertungen'}
                </span>
              </div>,
            ]}
        </div>
        <div className={s.availabilityColumn}>
          {offer && offer.avl && offer.avl.best_code === 0 && (
            <div className={s.nichtLagernd}>
              <FaCircle /> <span>nicht lagernd</span>
            </div>
          )}
          {offer && offer.avl && offer.avl.best_code === 1 && (
            <div className={s.kurzfristigLieferbar}>
              <FaCircle /> <span>kurzfristig lieferbar</span>
            </div>
          )}
          {offer && offer.avl && offer.avl.best_code === 2 && (
            <div className={s.lagernd}>
              <FaCircle /> <span>lagernd</span>
            </div>
          )}
        </div>
        <div className={s.descriptionColumn}>
          {offer &&
            offer.shop &&
            offer.shop.prod_desc &&
            offer.shop.prod_desc.map(d => (
              <div key={d} style={{ margin: '5px 0' }}>
                {d}
              </div>
            ))}
        </div>
      </div>
    );
  }

  render() {
    const {
      categoryLists,
      addItemToCategoryLists,
      removeItemFromCategoryLists,
    } = this.props;
    const {
      loadingRuleSet,
      ruleSet,
      loadingProductData,
      productDetailsData,
      loadingUpsellingProducts,
      upsellingProducts,
      upsellingReferenceProduct,
      columnKeys,
      columnScores,
      errors,
      expanded,
      cardsPerPage, // eslint-disable-line no-unused-vars
      columnBoost,
      processBuying,
      showBuyModal,
    } = this.state;

    if (loadingRuleSet || loadingProductData) return <Loading />;

    if (!loadingRuleSet && ruleSet == null) {
      return <div>Invalid category</div>;
    }

    if (errors && errors.length > 0) {
      return (
        <div className={s.productPage}>
          {errors.length > 0 && <Error errors={errors} />}
        </div>
      );
    }

    if (
      !productDetailsData ||
      !productDetailsData.productDetails ||
      !productDetailsData.danubeProduct ||
      !productDetailsData.product
    ) {
      return <div className={s.productPage}>Error while loading data.</div>;
    }

    const { product, productDetails } = productDetailsData;

    let minPrice = -1;
    let maxPrice = -1;

    if (productDetails.offers) {
      productDetails.offers.forEach(offer => {
        const price = offer.price.value;
        if (minPrice === -1 || price < minPrice) {
          minPrice = price;
        }
        if (maxPrice === -1 || price > maxPrice) {
          maxPrice = price;
        }
      });
    }

    let properties = [];
    if (product.description) {
      const secondaryProperties = [];
      product.description.forEach(d => {
        if (
          ruleSet == null ||
          ruleSet.rules.find(r => r.from === d.prop || r.property === d.prop)
        ) {
          properties.push(d);
        } else if (expanded) {
          secondaryProperties.push(d);
        }
      });
      properties = [...properties, ...secondaryProperties];
    }

    return (
      <div className={s.productPage}>
        <Breadcrumbs
          path={[
            {
              url: `/${this.props.match.params.category}`,
              label: this.props.match.params.category,
            },
            {
              url: `/${this.props.match.params.category}/product/${product.id}`,
              label: product.product,
            },
          ]}
        />
        <>
          <div className={s.leftContainer}>
            <div className={s.imagesContainer}>
              <ImagesList images={productDetails.images} maxImageHeight={260} />
            </div>
            <div className={s.priceRangeContainer}>
              <h2>Aktueller Preisbereich</h2>
              {minPrice > -1 &&
                maxPrice > -1 && [
                  <span key="min-price" className={s.priceTag}>
                    € {minPrice}
                  </span>,
                  <span key="price-range-separator"> bis </span>,
                  <span key="max-price" className={s.priceTag}>
                    € {maxPrice}
                  </span>,
                ]}
              {(minPrice === -1 || maxPrice === -1) && (
                <span className={s.priceTag}>€ {product.best_price}</span>
              )}
            </div>
            <div className={s.buyContainer}>
              <button
                className="btn btn-secondary btn-round"
                onClick={() => {
                  this.handleBuyClicked(product);
                }}
              >
                Kaufen
              </button>
            </div>
          </div>
          <div className={s.descriptionContainer}>
            <h1>
              <a
                href={`https://geizhals.at/${product.product
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/\//g, '-')}-a${product.id}.html?hloc=at`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {product.product}
              </a>
            </h1>
            <div className={s.ratingLine}>
              <div className={s.starsWrapper}>
                {product.rating_stars
                  ? renderStars(product.rating_stars, 5)
                  : renderInactiveStars(5)}
              </div>
              <span>
                {product.rating_stars ? product.rating_stars.toFixed(1) : '0.0'}
              </span>
              &nbsp;
              <span>
                {product.rating_count
                  ? ` | ${product.rating_count} Bewertungen`
                  : ' | 0 Bewertungen'}
              </span>
            </div>
            <div className={s.ratingLine}>
              {(product.hitrate || product.hitrate === 0) && (
                <span>
                  <strong>Up-selling Performance: </strong>
                  {(product.hitrate * 100).toFixed(2)}%
                </span>
              )}
            </div>
            <div className={s.propertiesContainer}>
              {properties.map((p, index) => (
                <div
                  key={p.prop}
                  className={
                    index % 2 === 0 ? s.oddPropertyLine : s.evenPropertyLine
                  }
                >
                  <div className={s.propTag}>{p.prop}</div>
                  <div className={s.valueTag}>{p.value}</div>
                </div>
              ))}
              <button
                className={s.expandButton}
                onClick={() => {
                  this.setState({ expanded: !expanded });
                }}
              >
                {expanded ? <FaCaretUp /> : <FaCaretDown />}
              </button>
            </div>
          </div>
        </>
        <div className={s.upsellingTableContainer}>
          <h2>
            Diese Produkte haben ein ähnliches oder besseres
            Preis-/Leistungsverhältnis
          </h2>
          <Form.Group className={s.manufacturerFilter}>
            <Form.Check
              id="manufacturer-filter"
              type="checkbox"
              label="Nur Produkte des gleichen Herstellers anzeigen"
              checked={this.state.manufacturerFilterActive}
              onChange={evt => {
                this.setState(
                  {
                    manufacturerFilterActive: evt.target.checked,
                  },
                  () => {
                    this.findBestUpSellingProduct();
                  },
                );
              }}
              disabled={loadingUpsellingProducts}
            />
          </Form.Group>
          <ProductComparisionTable
            columnKeys={columnKeys}
            columnScores={columnScores}
            category={this.props.match.params.category}
            referenceProducts={
              upsellingReferenceProduct ? [upsellingReferenceProduct] : null
            }
            loading={loadingUpsellingProducts}
            upsellingProducts={upsellingProducts}
            onUpsellingProductClicked={upsellingProduct => {
              this.handleUpSellingClicked(product, upsellingProduct);
            }}
            onAttributeClick={this.handleAttributeClick}
            columnBoost={columnBoost}
            ruleSet={ruleSet}
            categoryLists={categoryLists}
            addItemToCategoryLists={addItemToCategoryLists}
            removeItemFromCategoryLists={removeItemFromCategoryLists}
          />
          <CardRecommendationWidget />
        </div>
        {productDetails.offers && (
          <div className={s.offersContainer}>
            <h2>{productDetails.offer_count} Angebote</h2>
            {this.renderOfferHeaders()}
            {productDetails.offers.map((offer, index) =>
              this.renderOfferLine(offer, index),
            )}
          </div>
        )}
        <BuyModal
          show={showBuyModal}
          onClose={() => {
            this.setState({ showBuyModal: false });
          }}
          product={product.product}
        />
        {processBuying ? <Loading /> : null}
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  CategoryListsWrapper,
  withRouter,
  withStyles(ProductListStyle, s),
)(ProductPage);
