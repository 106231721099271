exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vqZ3h{width:100%;margin-top:48px}.vqZ3h,.vqZ3h ._3VpOU{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:start;align-items:flex-start}.vqZ3h ._3VpOU ._24pQq{width:100%;min-height:24px;margin-top:4px}.vqZ3h ._3VpOU ._24pQq ._3XEkG{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:end;justify-content:flex-end;-ms-flex-align:center;align-items:center}.vqZ3h ._3VpOU ._24pQq ._3Jr4G{margin-top:4px;width:100%}.vqZ3h ._3VpOU ._3iX6q{width:1728px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;background-color:#f4f6fb;padding:20px 0}.vqZ3h ._3VpOU ._3iX6q .K3ZgQ{display:inline-block;width:180px;height:300px;margin:0 18px;-webkit-box-shadow:4px 4px 8px 0 hsla(0,0%,64%,.75);box-shadow:4px 4px 8px 0 hsla(0,0%,64%,.75)}.vqZ3h ._3VpOU ._3iX6q .K3ZgQ:hover{cursor:pointer}.vqZ3h ._3VpOU ._2z_ZS{width:100%;color:#333;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:start;align-items:flex-start;padding:10px 20px}.vqZ3h ._3VpOU ._2z_ZS ._1ItBu{text-decoration:underline}.vqZ3h ._3VpOU ._2z_ZS ._2r8kd{color:#0db5ff}.vqZ3h ._3VpOU ._2z_ZS ._2r8kd:active,.vqZ3h ._3VpOU ._2z_ZS ._2r8kd:focus,.vqZ3h ._3VpOU ._2z_ZS ._2r8kd:hover{text-decoration:underline;cursor:pointer}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"widgetContainer": "vqZ3h",
	"widgetInner": "_3VpOU",
	"widgetCrown": "_24pQq",
	"danubeSignifier": "_3XEkG",
	"progressBar": "_3Jr4G",
	"cardsContainer": "_3iX6q",
	"cardWrapper": "K3ZgQ",
	"infoLine": "_2z_ZS",
	"underline": "_1ItBu",
	"resetButton": "_2r8kd"
};