/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ImagesList.scss';
import HorizontalList from '../HorizontalList';

class ImagesList extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    maxImageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    images: [],
    maxImageHeight: '100%',
  };

  constructor(props) {
    super(props);

    this.renderSlide = this.renderSlide.bind(this);
  }

  renderSlide(image) {
    const { maxImageHeight } = this.props;

    return (
      <div key={image} className={s.imageSlide}>
        <img src={image} alt="" style={{ maxHeight: maxImageHeight }} />
      </div>
    );
  }

  render() {
    const { images } = this.props;

    if (images == null || images.length === 0) {
      return <div className={s.noImagesContainer}>Keine Bilder verfügbar.</div>;
    }

    return (
      <div className={s.imagesListContainer}>
        <HorizontalList items={images} renderItem={this.renderSlide} />
      </div>
    );
  }
}

export default withStyles(s)(ImagesList);
