exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XaVQ ._3aFgC{padding:15px;border:1px solid #e5eaec;border-radius:5px;-webkit-box-shadow:0 5px 28px 0 rgba(0,0,0,.2);box-shadow:0 5px 28px 0 rgba(0,0,0,.2)}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"dashboard": "_2XaVQ",
	"widget": "_3aFgC"
};