import { CATEGORY_LISTS_LOCALSTORAGE_KEY } from '../constants';

export const getCategoryLists = () => {
  const data = localStorage.getItem(CATEGORY_LISTS_LOCALSTORAGE_KEY);
  if (data != null) return JSON.parse(data);
  return {};
};

export const addItemToCategoryLists = ({ category, itemId }) => {
  const categoryLists = getCategoryLists();
  if (categoryLists[category] == null) {
    categoryLists[category] = [];
  }
  if (!categoryLists[category].includes(itemId)) {
    categoryLists[category].push(itemId);
  }
  localStorage.setItem(
    CATEGORY_LISTS_LOCALSTORAGE_KEY,
    JSON.stringify(categoryLists),
  );
};

export const removeItemFromCategoryLists = ({ category, itemId }) => {
  const categoryLists = getCategoryLists();
  if (categoryLists[category] == null) {
    return;
  }
  const index = categoryLists[category].indexOf(itemId);
  if (index > -1) {
    categoryLists[category].splice(index, 1);
    if (categoryLists[category].length === 0) {
      delete categoryLists[category];
    }
  }
  localStorage.setItem(
    CATEGORY_LISTS_LOCALSTORAGE_KEY,
    JSON.stringify(categoryLists),
  );
};
