/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaPlusSquare from 'react-icons/lib/fa/plus-square';
import FaMinusSqare from 'react-icons/lib/fa/minus-square';
import FaArrowAltCircleUp from 'react-icons/lib/fa/arrow-circle-up';
import FaChecked from 'react-icons/lib/fa/check-square-o';
import FaEmpty from 'react-icons/lib/fa/square-o';

import s from './ProductComparisionTable.scss';
import Spinner from '../../Spinner';
import { danubeTagsMap } from '../../DanubeTags';
import { renderStars, renderInactiveStars } from '../../ProductList';

const excludedKeys = [
  'price_original',
  'memory_original',
  'display_inches_original',
  'display_pixels_original',
  'recommendationScore',
];

class ProductComparisionTable extends React.Component {
  static propTypes = {
    columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
    category: PropTypes.string.isRequired,
    referenceProducts: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    loading: PropTypes.bool.isRequired,
    upsellingProducts: PropTypes.arrayOf(PropTypes.object),
    onUpsellingProductClicked: PropTypes.func.isRequired,
    onAttributeClick: PropTypes.func.isRequired,
    columnBoost: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.string),
    }),
    ruleSet: PropTypes.shape({
      rules: PropTypes.array,
    }).isRequired,
    categoryLists: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    addItemToCategoryLists: PropTypes.func.isRequired,
    removeItemFromCategoryLists: PropTypes.func.isRequired,
  };

  static defaultProps = {
    referenceProducts: null,
    upsellingProducts: [],
    columnBoost: {
      columns: [],
    },
  };

  constructor(props) {
    super(props);

    this.renderPropertiesColumn = this.renderPropertiesColumn.bind(this);
    this.renderProductColumn = this.renderProductColumn.bind(this);
    this.renderUpsellingProductsColumns = this.renderUpsellingProductsColumns.bind(this); // eslint-disable-line prettier/prettier
  }

  // eslint-disable-next-line class-methods-use-this
  renderPropertiesColumn(sortedKeys) {
    const { columnKeys, columnScores, columnBoost, loading } = this.props;
    const filteredColumnScores = columnScores.filter(
      (score, index) => excludedKeys.indexOf(columnKeys[index]) === -1,
    );

    // normalize column scores
    const total = filteredColumnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = filteredColumnScores.map(
      score => (score / total) * 100,
    );

    return (
      <div className={s.headersRow}>
        <div className={s.productCell} />
        {sortedKeys.map((key, index) => {
          const sIndex = columnKeys.findIndex(cKey => cKey === key);
          return (
            <div
              key={key}
              // eslint-disable-next-line prettier/prettier
              className={`${s.cell} ${index % 2 === 0 ? s.oddCell : s.evenCell} ${s.attributeCell}`}
            >
              <button
                onClick={() => {
                  this.props.onAttributeClick({ attribute: key });
                }}
                disabled={loading}
              >
                {sIndex >= 0 ? (
                  <div className={s.importanceTag}>
                    {Math.round(normalizedColumnScores[sIndex])}%
                  </div>
                ) : (
                  ''
                )}
                {danubeTagsMap[key] ? (
                  <FormattedMessage {...danubeTagsMap[key]} />
                ) : (
                  <span>{key}</span>
                )}
                {columnBoost.columns.indexOf(key) !== -1 && (
                  <FaArrowAltCircleUp />
                )}
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderProductColumn(
    sortedKeys,
    product,
    referenceProducts,
    isReference = false,
  ) {
    const {
      ruleSet: { rules },
      category,
      categoryLists,
      addItemToCategoryLists,
      removeItemFromCategoryLists,
    } = this.props;

    if (product == null || referenceProducts == null) {
      return null;
    }

    const isChecked =
      categoryLists &&
      categoryLists[category] &&
      categoryLists[category].includes(product.product.id);

    return (
      <div key={product.product.id} className={s.productRow}>
        <div className={`${s.productCell} ${isReference ? s.selected : ''}`}>
          <a
            href={`/${this.props.category}/product/${
              product.product.id
            }?refId=${referenceProducts[0].product.id}`}
            onClick={() => {
              this.props.onUpsellingProductClicked(product.product);
            }}
          >
            <img src={product.product.image_thumb} alt="" />
            <div>
              <b>{product.product.product}</b>
            </div>
          </a>
          {categoryLists && (
            <button
              className={s.toggleButton}
              onClick={() => {
                if (isChecked) {
                  removeItemFromCategoryLists({
                    category,
                    itemId: product.product.id,
                  });
                } else {
                  addItemToCategoryLists({
                    category,
                    itemId: product.product.id,
                  });
                }
              }}
            >
              {isChecked ? <FaChecked /> : <FaEmpty />}
            </button>
          )}
        </div>
        {sortedKeys.map((key, index) => {
          let isBetter = false;
          let isWorse = false;

          let rule = null;
          if (key === 'Gelistet seit') {
            rule = {
              property: 'Gelistet seit',
              type: 'INVERSE_PERCENTAGE_SINE',
            };
          } else {
            rule = rules.find(r => r.property === key);
          }

          const inverse = rule && rule.type.startsWith('INVERSE');

          if (!isReference) {
            if (product.betterIn != null) {
              isBetter = product.betterIn.includes(key);
            } else if (product.betterIns != null) {
              const betterIn = product.betterIns.find(bI => bI.prop === key);
              if (betterIn) {
                isBetter = betterIn.value === referenceProducts.length;
              }
            }

            if (product.worseIn != null) {
              isWorse = product.worseIn.includes(key);
            } else if (product.worseIns != null) {
              const worseIn = product.worseIns.find(wI => wI.prop === key);
              if (worseIn) {
                isWorse = worseIn.value === referenceProducts.length;
              }
            }
          }

          let icon = <div className={s.compareIcon} />;
          if (isBetter) {
            if (inverse) {
              icon = (
                <FaMinusSqare className={`${s.compareIcon} ${s.better}`} />
              );
            } else {
              icon = (
                <FaPlusSquare className={`${s.compareIcon} ${s.better}`} />
              );
            }
          } else if (isWorse) {
            if (inverse) {
              icon = <FaPlusSquare className={`${s.compareIcon} ${s.worse}`} />;
            } else {
              icon = <FaMinusSqare className={`${s.compareIcon} ${s.worse}`} />;
            }
          }

          const field = product.danubeProduct.fields.find(f => f.field === key);
          let value = field ? field.value : null;

          if (key === 'Nutzerbewertung') {
            const inactive =
              key === 'Nutzerbewertung' && !product.product.rating_count;
            const stars = inactive
              ? renderInactiveStars(5)
              : renderStars(value || 0, 5);
            value = [
              <div key="stars" className={s.starsWrapper}>
                {stars}
              </div>,
              <div key="rating" className={s.ratingWrapper}>
                {!inactive && value}
              </div>,
            ];
          }

          return (
            <div
              key={key}
              // eslint-disable-next-line prettier/prettier
              className={`${s.cell} ${index % 2 === 0 ? s.oddCell : s.evenCell} ${isReference ? s.selected : ''}`}
            >
              {icon}
              <span>
                {/* eslint-disable-next-line no-nested-ternary */}
                {rule.type === 'OVERLAP_LOCAL' ||
                rule.type === 'OVERLAP_GLOBAL' ||
                rule.type === 'OVERLAP_SELECTION'
                  ? value != null
                    ? value.split(',').join(', ') || '-'
                    : ''
                  : value}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderUpsellingProductsColumns(sortedKeys) {
    const { referenceProducts, loading, upsellingProducts } = this.props;

    if (loading) {
      return (
        <div className={s.loadingUpsellingProductsContainer}>
          <Spinner show margin="0" fontSize="4px" />
        </div>
      );
    }

    return (
      <div className={s.upsellingProductsContainer}>
        {upsellingProducts.map(p =>
          this.renderProductColumn(sortedKeys, p, referenceProducts),
        )}
      </div>
    );
  }

  render() {
    const { columnKeys, columnScores, referenceProducts } = this.props;

    let sortedKeys = [];

    if (columnKeys && columnKeys.length > 0) {
      sortedKeys = columnKeys
        .map((key, index) => ({
          key,
          score: columnScores[index],
        }))
        .map(ks => ks.key)
        .filter(key => !excludedKeys.includes(key));
    }

    return (
      <div>
        <div className={s.productComparisionTable}>
          {this.renderPropertiesColumn(sortedKeys)}
          {referenceProducts && (
            <div className={s.referenceProductsContainer}>
              {referenceProducts.map(referenceProduct =>
                this.renderProductColumn(
                  sortedKeys,
                  referenceProduct,
                  referenceProducts,
                  true,
                ),
              )}
            </div>
          )}
          {this.renderUpsellingProductsColumns(sortedKeys)}
        </div>
        <span className={s.infoText}>
          Klicke auf einen Prozent-Tag, um die entsprechende Eigenschaft stärker
          zu gewichten!
        </span>
      </div>
    );
  }
}

export default withStyles(s)(ProductComparisionTable);
