exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FmT36{width:100%;max-width:100%;margin:0 auto}.FmT36 ._3n_k3{padding:40px 20px 20px;text-align:center;font-size:20px}.FmT36 ._3n_k3 h1{margin:0 0 20px;font-size:25px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"myListsPage": "FmT36",
	"infoTextWrapper": "_3n_k3"
};