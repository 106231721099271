/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './AnalyticsDashboard.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import * as queries from './queries';
import GlobalHitRateLineChart from './GlobalHitRateLineChart';

class AnalyticsDashboard extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.fetchGlobalHitRate = this.fetchGlobalHitRate.bind(this);
  }

  async fetchGlobalHitRate() {
    const { client } = this.context;
    const globalPerformanceData = await client.query({
      query: queries.globalPerformanceQuery,
      fetchPolicy: 'network-only',
    });

    if (
      globalPerformanceData &&
      globalPerformanceData.data &&
      globalPerformanceData.data.globalPerformance &&
      globalPerformanceData.data.globalPerformance.performanceTimeSeriesJSON
    ) {
      const timeSeries = JSON.parse(
        globalPerformanceData.data.globalPerformance.performanceTimeSeriesJSON,
      );
      const hitrates = {};
      Object.entries(timeSeries).forEach(([date, { hitrate }]) => {
        hitrates[date] = (hitrate * 100).toFixed(2);
      });
      return hitrates;
    }
    return {};
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          <div className={s.dashboard}>
            <div className={s.widget} style={{ width: '50%' }}>
              <GlobalHitRateLineChart onFetchData={this.fetchGlobalHitRate} />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(AnalyticsDashboard));
