export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';

export const CATEGORY_LISTS_LOCALSTORAGE_KEY = 'CATEGORY_LISTS';

export const MAX_RECOMMENDATION_ITEMS = 8;
