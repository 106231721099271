exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wpFJ{width:100%;height:100%}._3wpFJ ._2__iz{width:100%;margin-bottom:15px}._3wpFJ ._2__iz ._2undK{display:inline-block;width:calc(100% - 150px);vertical-align:middle}._3wpFJ ._2__iz ._2undK h2{margin-top:0;font-size:20px}._3wpFJ ._2__iz .MNlo7{display:inline-block;width:150px;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"globalHitRateLineChart": "_3wpFJ",
	"topbar": "_2__iz",
	"header": "_2undK",
	"timeGranularitySelection": "MNlo7"
};