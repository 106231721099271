/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Form } from 'react-bootstrap';

// eslint-disable-next-line css-modules/no-unused-class
import ProductPageStyles from '../ProductPage/ProductPage.scss';
import {
  findBestUpSellingProductsQuery,
  getRuleSetQuery,
} from '../ProductList';
import Loading from '../Loading';
import ProductComparisionTable from '../ProductPage/ProductComparisionTable';
import Error from '../Error';
import categoryNames from '../../util/productCategories';
import newCategoryNames from '../../util/newProductCategories';
import { arraysEqual } from '../../util/arrayUtils';

const allCategoryNames = { ...categoryNames, ...newCategoryNames };

class SingleCategoryList extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    category: PropTypes.string.isRequired,
    referenceProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    categoryLists: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    addItemToCategoryLists: PropTypes.func.isRequired,
    removeItemFromCategoryLists: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingRuleSet: true,
      ruleSet: null,
      loadingUpsellingProducts: false,
      upsellingProducts: [],
      upsellingReferenceProducts: null,
      columnKeys: [],
      columnScores: [],
      errors: [],
      manufacturerFilterActive: false,
      columnBoost: {
        columns: [],
      },
    };

    this.loadRuleSet = this.loadRuleSet.bind(this);
    this.findBestUpSellingProduct = this.findBestUpSellingProduct.bind(this);
    this.handleAttributeClick = this.handleAttributeClick.bind(this);
  }

  componentDidMount() {
    this.loadRuleSet();
  }

  componentDidUpdate(prevProps) {
    if (
      !arraysEqual(
        prevProps.categoryLists[prevProps.category],
        this.props.categoryLists[this.props.category],
      )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        { manufacturerFilterActive: false },
        this.findBestUpSellingProduct,
      );
    }
  }

  async loadRuleSet() {
    try {
      this.setState({ loadingRuleSet: true });

      const result = await this.context.client.query({
        query: getRuleSetQuery,
        variables: {
          category: this.props.category,
        },
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getRuleSet) {
        if (result.data.getRuleSet.errors) {
          this.setState({
            loadingRuleSet: false,
            errors: [result.data.getRuleSet.errors],
          });
        } else {
          this.setState(
            {
              loadingRuleSet: false,
              ruleSet: JSON.parse(result.data.getRuleSet.data),
            },
            () => {
              if (this.state.ruleSet != null) {
                this.findBestUpSellingProduct();
              }
            },
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingRuleSet: false,
        errors: [e.message],
      });
    }
  }

  async findBestUpSellingProduct() {
    let manufacturers = null;
    if (
      this.state.manufacturerFilterActive &&
      this.state.upsellingReferenceProducts
    ) {
      manufacturers = this.state.upsellingReferenceProducts
        .map(upsellingReferenceProduct => {
          const manufacturerField = upsellingReferenceProduct.danubeProduct.fields.find(
            f => f.field === 'manufacturer',
          );
          return manufacturerField ? manufacturerField.value : null;
        })
        .filter(manufacturer => manufacturer != null);

      manufacturers = [...new Set(manufacturers)];
    }

    this.setState({
      loadingUpsellingProducts: true,
      upsellingProducts: [],
      upsellingReferenceProducts: null,
    });

    const result = await this.context.client.query({
      query: findBestUpSellingProductsQuery,
      variables: {
        referenceProductIds: this.props.referenceProductIds,
        category: this.props.category,
        filter: { manufacturers },
        columnBoost: this.state.columnBoost,
        stripUnimportantFields: false,
        stripColumnScores: false,
      },
    });

    if (result && result.data && result.data.findBestUpSellingProducts) {
      this.setState({
        loadingUpsellingProducts: false,
        upsellingProducts: result.data.findBestUpSellingProducts.products,
        upsellingReferenceProducts:
          result.data.findBestUpSellingProducts.referenceProducts,
        columnKeys: result.data.findBestUpSellingProducts.columnKeys,
        columnScores: result.data.findBestUpSellingProducts.columnScores,
      });
    } else {
      this.setState({
        loadingUpsellingProducts: false,
        upsellingProducts: [],
        upsellingReferenceProducts: null,
        columnKeys: [],
        columnScores: [],
      });
    }
  }

  handleAttributeClick({ attribute }) {
    if (this.state.loadingUpsellingProducts) {
      return;
    }

    const { columnBoost } = this.state;
    const index = columnBoost.columns.indexOf(attribute);
    if (index !== -1) {
      columnBoost.columns.splice(index, 1);
    } else {
      columnBoost.columns.push(attribute);
    }
    this.setState({ columnBoost }, () => {
      this.findBestUpSellingProduct();
    });
  }

  render() {
    const {
      category,
      categoryLists,
      addItemToCategoryLists,
      removeItemFromCategoryLists,
    } = this.props;
    const {
      loadingRuleSet,
      ruleSet,
      loadingUpsellingProducts,
      upsellingProducts,
      upsellingReferenceProducts,
      columnKeys,
      columnScores,
      errors,
      columnBoost,
    } = this.state;

    if (loadingRuleSet) return <Loading />;

    if (!loadingRuleSet && ruleSet == null) {
      return <div>Invalid category</div>;
    }

    if (errors && errors.length > 0) {
      return (
        <div className={ProductPageStyles.productPage}>
          {errors.length > 0 && <Error errors={errors} />}
        </div>
      );
    }

    return (
      <div className={ProductPageStyles.upsellingTableContainer}>
        <h2>{allCategoryNames[category] || category}</h2>
        <Form.Group className={ProductPageStyles.manufacturerFilter}>
          <Form.Check
            id={`${category}_manufacturer-filter`}
            type="checkbox"
            label="Nur Produkte des gleichen Herstellers anzeigen"
            checked={this.state.manufacturerFilterActive}
            onChange={evt => {
              this.setState(
                {
                  manufacturerFilterActive: evt.target.checked,
                },
                () => {
                  this.findBestUpSellingProduct();
                },
              );
            }}
            disabled={loadingUpsellingProducts}
          />
        </Form.Group>
        <ProductComparisionTable
          columnKeys={columnKeys}
          columnScores={columnScores}
          category={category}
          referenceProducts={upsellingReferenceProducts}
          loading={loadingUpsellingProducts}
          upsellingProducts={upsellingProducts}
          onUpsellingProductClicked={() => {}}
          onAttributeClick={this.handleAttributeClick}
          columnBoost={columnBoost}
          ruleSet={ruleSet}
          categoryLists={categoryLists}
          addItemToCategoryLists={addItemToCategoryLists}
          removeItemFromCategoryLists={removeItemFromCategoryLists}
        />
      </div>
    );
  }
}

export default withStyles(ProductPageStyles)(SingleCategoryList);
