import gql from 'graphql-tag';

const productPerformanceQuery = gql`
  query productPerformanceQuery($productId: Int!) {
    productPerformance(productId: $productId) {
      totalHitrate
      performanceTimeSeries {
        suggestionsCount
        hitCount
        date
        hitrate
      }
      performanceTimeSeriesJSON
    }
  }
`;

const globalPerformanceQuery = gql`
  query globalPerformanceQuery {
    globalPerformance {
      currentHitrate
      performanceTimeSeries {
        suggestionsCount
        hitCount
        date
        hitrate
      }
      performanceTimeSeriesJSON
    }
  }
`;

export { productPerformanceQuery, globalPerformanceQuery };
