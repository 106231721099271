import React from 'react';

import ProductPage from 'components/ProductPage';

class ProductPageWrapper extends React.Component {
  render() {
    return <ProductPage />;
  }
}

export default ProductPageWrapper;
