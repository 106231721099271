import React from 'react';

import MyLists from 'components/MyListsPage';

class MyListsWrapper extends React.Component {
  render() {
    return <MyLists />;
  }
}

export default MyListsWrapper;
