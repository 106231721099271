import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './LandingPage.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';

class LandingPage extends React.Component {
  render() {
    return (
      <div className={s.landingPageContainer}>
        <h1>Dies ist eine danube.ai E-commerce Demo</h1>
        Bitte wähle eine Produktkategorie aus der Kopf- oder Seitenleiste aus.
      </div>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(LandingPage));
