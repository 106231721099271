/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-bootstrap';
import FaArrowUp from 'react-icons/lib/fa/arrow-up';
import FaArrowDown from 'react-icons/lib/fa/arrow-down';
import { FormattedMessage } from 'react-intl';

import s from './BetterAndWorseTagsModal.scss';
import UpsellingProductsListStyle from './UpsellingProductsList.scss'; // eslint-disable-line css-modules/no-unused-class
import { danubeTagsMap } from '../DanubeTags';

class BetterAndWorseTagsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    betterIn: PropTypes.arrayOf(PropTypes.string).isRequired,
    worseIn: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  render() {
    const { show, onClose, title, betterIn, worseIn } = this.props;

    let betterTags = [];
    if (betterIn.length === 1 && betterIn[0] === 'recommendationScore') {
      betterTags = [
        <div
          key="recommendationScore"
          // eslint-disable-next-line prettier/prettier
          className={`${UpsellingProductsListStyle.betterTag} ${UpsellingProductsListStyle.largeTag}`}
        >
          <FaArrowUp />
          <span>Größere Beliebtheit</span>
        </div>,
      ];
    } else {
      betterTags = betterIn
        .filter(property => property !== 'recommendationScore')
        .map(property => (
          <div
            key={property}
            // eslint-disable-next-line prettier/prettier
            className={`${UpsellingProductsListStyle.betterTag} ${UpsellingProductsListStyle.largeTag}`}
          >
            <FaArrowUp />
            {danubeTagsMap[property] ? (
              <FormattedMessage {...danubeTagsMap[property]} />
            ) : (
              <span>{property}</span>
            )}
          </div>
        ));
    }

    const worseTags = worseIn.map(property => (
      <div
        key={property}
        // eslint-disable-next-line prettier/prettier
        className={`${UpsellingProductsListStyle.worseTag} ${UpsellingProductsListStyle.largeTag}`}
      >
        <FaArrowDown />
        {danubeTagsMap[property] ? (
          <FormattedMessage {...danubeTagsMap[property]} />
        ) : (
          <span>{property}</span>
        )}
      </div>
    ));

    return (
      <Modal
        show={show}
        onHide={onClose}
        dialogClassName={s.tagsModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={s.tagsModalTitle}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={s.tagsModalBody}>
          {betterTags.length > 0 && [
            <h3 key="betterTagsHeader">Bessere Eigenschaften:</h3>,
            <div key="betterTags">{betterTags}</div>,
          ]}
          {worseTags.length > 0 && [
            <h3 key="worseTagsHeader">Schlechtere Eigenschaften:</h3>,
            <div key="worseTags">{worseTags}</div>,
          ]}
        </Modal.Body>
      </Modal>
    );
  }
}

export default withStyles(s, UpsellingProductsListStyle)(
  BetterAndWorseTagsModal,
);
