exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mc2jP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100%;height:100%;text-align:center}._1eDfB{padding:0 10px}._1eDfB,._1eDfB ._2VUP2{-ms-flex:1 1;flex:1 1;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100%;height:100%}._1eDfB ._2VUP2{text-align:center}._1eDfB ._2VUP2 a{text-decoration:underline}._1eDfB ._2VUP2 a img{display:block;margin:5px auto}._1eDfB ._2VUP2 ._1h1TC{width:100%;margin-top:3px;padding:7px 3px;border-radius:7px}._1eDfB ._2VUP2 ._1h1TC:hover{cursor:pointer;background-color:#e5eaec}._1eDfB ._2VUP2 ._2WDod{display:block;margin:0 auto;color:#115699;font-size:1.2em;font-weight:700}._1XdYA,._2VOdn{display:inline-block;margin:2px 3px 2px 0;padding:2px 7px 2px 5px;border-radius:5px;font-size:12px}._1XdYA span,._1XdYA svg,._2VOdn span,._2VOdn svg{vertical-align:middle}._1XdYA svg,._2VOdn svg{margin-right:5px}.QoXTp{font-size:14px}._1XdYA{background-color:#0db5ff;color:#fff}._1XdYA svg{margin-top:-2px}._2VOdn{background-color:#fa654b;color:#fff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loadingContainer": "mc2jP",
	"upsellingProductSlide": "_1eDfB",
	"upsellingProductSlideContent": "_2VUP2",
	"betterAndWorseTagsContainer": "_1h1TC",
	"upsellingPrice": "_2WDod",
	"betterTag": "_1XdYA",
	"worseTag": "_2VOdn",
	"largeTag": "QoXTp"
};