/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// eslint-disable-next-line css-modules/no-unused-class
import s from './MyListsPage.scss';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import CategoryListsWrapper from '../LocalStorage/CategoryListsWrapper';
import SingleCategoryList from './SingleCategoryList';

class MyListsPage extends React.Component {
  static propTypes = {
    categoryLists: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    addItemToCategoryLists: PropTypes.func.isRequired,
    removeItemFromCategoryLists: PropTypes.func.isRequired,
  };

  render() {
    const {
      categoryLists,
      addItemToCategoryLists,
      removeItemFromCategoryLists,
    } = this.props;

    if (Object.keys(categoryLists).length > 0) {
      return (
        <div className={s.myListsPage}>
          {Object.keys(categoryLists).map(key => (
            <SingleCategoryList
              key={key}
              category={key}
              referenceProductIds={categoryLists[key]}
              categoryLists={categoryLists}
              addItemToCategoryLists={addItemToCategoryLists}
              removeItemFromCategoryLists={removeItemFromCategoryLists}
            />
          ))}
        </div>
      );
    }
    return (
      <div className={s.myListsPage}>
        <div className={s.infoTextWrapper}>
          <h1>Hier findest du deine persönlichen Produkt-Listen</h1>
          Füge Produkte über die globalen Kategorie-Listen oder über die
          Detail-Seiten zu deinen persönlichen Listen hinzu, um einen
          kombinierten Vergleich zu starten.
        </div>
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  CategoryListsWrapper,
  withRouter,
  withStyles(s),
)(MyListsPage);
