import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // tags
  recommendationScore: {
    id: 'demo.geizhals.results.recommendationScore',
    defaultMessage: 'Recommendation Frequency',
    description: 'label for field in results list',
  },
});

export default messages;
