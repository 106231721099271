import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';

const loginQuery = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      token
    }
  }
`;

const meQuery = gql`
  query me {
    me {
      id
      username
      active
    }
  }
`;

function setTokenCookie({ token }) {
  Cookies.set('id_token', token, { expires: 7 });
}

function getTokenCookie() {
  return Cookies.get('id_token');
}

function withLogin(WrappedComponent) {
  class LoginWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.login = this.login.bind(this);
    }

    async login({ username, password }) {
      const response = await this.context.client.query({
        query: loginQuery,
        variables: {
          username,
          password,
        },
        fetchPolicy: 'network-only',
      });
      if (
        response &&
        response.data &&
        response.data.login &&
        response.data.login.success
      ) {
        setTokenCookie({ token: response.data.login.token });
        return true;
      }
      return false;
    }

    render() {
      return <WrappedComponent login={this.login} {...this.props} />;
    }
  }

  return LoginWrapper;
}

export { withLogin, getTokenCookie, loginQuery, meQuery };
