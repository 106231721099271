exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3X-DF{width:235px;min-width:-webkit-max-content;min-width:-moz-max-content;min-width:max-content;height:100%;min-height:calc(100vh - 88px);padding:15px;color:#fff;background-color:#115699;border-radius:5px}@media (max-width:1440px){._3X-DF{width:180px}}._3X-DF a{padding-left:0;padding-right:0;color:hsla(0,0%,100%,.8)}._3X-DF a:active,._3X-DF a:focus,._3X-DF a:hover{color:#fff}._3X-DF hr{border-top:1px solid hsla(0,0%,100%,.5)}._3X-DF .h8IWR{display:block;width:100%;font-size:.8em;text-align:center}._3X-DF .h8IWR a{color:#0db5ff}._3X-DF .h8IWR img{display:block;width:40px;margin:5px auto}._3X-DF ._4URBW{margin-top:30px;text-align:center}._3X-DF ._4URBW button{background:none;border:none;outline:none;-webkit-box-shadow:none;box-shadow:none;color:#0db5ff;font-size:30px}._3X-DF ._7GQUs{color:#f6a635}._3X-DF ._7GQUs:hover{color:#ffed92}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"sidebar": "_3X-DF",
	"poweredBy": "h8IWR",
	"reloadWrapper": "_4URBW",
	"newCategory": "_7GQUs"
};