exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1u8X7{background-color:#e5f7fe!important}._1mQD3,._1u8X7{opacity:1!important;height:8px!important;width:100%!important}._1mQD3{background-color:#fff!important}._2lOTH{height:20px;width:100%;background:transparent;display:-ms-flexbox;display:flex;-ms-flex-align:end;align-items:flex-end;overflow:hidden;opacity:1;-webkit-transition:opacity .2s linear;-o-transition:opacity .2s linear;transition:opacity .2s linear}._2lOTH ._3TfCh{width:0;position:relative;height:20px;background-color:#0db5ff;-webkit-transition:width .25s ease-in-out;-o-transition:width .25s ease-in-out;transition:width .25s ease-in-out}._2lOTH ._3TfCh ._13kdw{position:absolute;top:0;bottom:0;left:16.66667px;right:16.66667px}._2lOTH ._3TfCh ._13kdw ._1ywAq{display:inline-block;position:absolute;width:100px;height:100%;bottom:0;right:-46px;padding-top:1px;color:#fff;font-weight:700;text-align:center;-webkit-clip-path:polygon(0 0,66% 0,100% 100%,33% 100%);clip-path:polygon(0 0,66% 0,100% 100%,33% 100%);z-index:1;-webkit-transition:background-color .25s ease-in-out;-o-transition:background-color .25s ease-in-out;transition:background-color .25s ease-in-out}._2lOTH ._3xCVV{display:-ms-flexbox;display:flex;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1}._2lOTH ._3pp7E{background-color:transparent;opacity:.6!important;-ms-flex-positive:1;flex-grow:1}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"backgroundHelper": "_1u8X7",
	"whiteBackgroundHelper": "_1mQD3",
	"userMetricsprogressBarContainer": "_2lOTH",
	"userMetricsProgressBarFilled": "_3TfCh",
	"userMetricsLabelWrapper": "_13kdw",
	"userMetricsLabel": "_1ywAq",
	"userMetricsProgressBarNotFilled": "_3xCVV",
	"userMetricsProgressBar": "_3pp7E"
};