/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';

import s from './GlobalHitRateLineChart.scss';

/*
 * Determines by how much the global hitrate is multiplied. This should be equal to the number of suggestions per product.
 *
 * Note: This factor should only be applied for global statistics (not for individual products), since we want to measure
 * how often there has been a hit in general, i.e. if any of the X suggested products was a hit.
 */
const NORMALIZATION_FACTOR = 5;

const FETCH_INTERVAL = 5000;

export const LAST_WEEK = 'LAST_WEEK';
export const LAST_MONTH = 'LAST_MONTH';

const TIME_GRANULARITIES = [
  { value: LAST_WEEK, label: 'Last week' },
  { value: LAST_MONTH, label: 'Last month' },
];

class GlobalHitRateLineChart extends React.Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formattedData: {
        labels: [],
        datasets: [],
      },
      selectedTimeGranularity: TIME_GRANULARITIES[0],
    };

    this.fetchData = this.fetchData.bind(this);
    this.formatData = this.formatData.bind(this);
    this.onTimeGranularityChanged = this.onTimeGranularityChanged.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.fetchTimeout) clearTimeout(this.fetchTimeout);
  }

  async fetchData() {
    const { selectedTimeGranularity } = this.state;
    const data = await this.props.onFetchData(selectedTimeGranularity);
    this.formatData(data);

    // clear and restart timeout
    if (this.fetchTimeout) clearTimeout(this.fetchTimeout);
    this.fetchTimeout = setTimeout(this.fetchData, FETCH_INTERVAL);
  }

  formatData(data) {
    const { selectedTimeGranularity } = this.state;

    const formattedData = {
      labels: [],
      datasets: [],
    };

    // determine labels
    let startDate;
    switch (selectedTimeGranularity.value) {
      case LAST_WEEK:
        startDate = moment().subtract(1, 'week');
        break;
      case LAST_MONTH:
        startDate = moment().subtract(1, 'month');
        break;
      default:
        startDate = moment();
        break;
    }

    const todayDate = moment();
    let currentDate = startDate.clone();

    while (todayDate.diff(currentDate, 'days') > 0) {
      currentDate = currentDate.clone().add(1, 'day');
      formattedData.labels.push(currentDate.format('YYYY-MM-DD'));
    }

    // define dataset
    const dataset = {
      label: 'Global Hit Rate in %',
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#0db5ff',
      borderColor: '#0db5ff',
      pointBorderColor: '#0db5ff',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 8,
      pointHoverRadius: 10,
      pointHoverBackgroundColor: '#0db5ff',
      pointHoverBorderColor: '#0db5ff',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    };

    // set data points
    formattedData.labels.forEach(label => {
      if (data[label]) {
        dataset.data.push(data[label] * NORMALIZATION_FACTOR);
      } else {
        dataset.data.push(0);
      }
    });

    formattedData.datasets.push(dataset);
    this.setState({ formattedData });
  }

  onTimeGranularityChanged(timeGranularity) {
    // clear timeout if needed
    if (this.fetchTimeout) clearTimeout(this.fetchTimeout);

    // set selection and refetch data
    this.setState({ selectedTimeGranularity: timeGranularity }, this.fetchData);
  }

  render() {
    const { formattedData, selectedTimeGranularity } = this.state;

    return (
      <div className={s.globalHitRateLineChart}>
        <div className={s.topbar}>
          <div className={s.header}>
            <h2>Global Hit Rate in %</h2>
          </div>
          <div className={s.timeGranularitySelection}>
            <Select
              value={selectedTimeGranularity}
              onChange={this.onTimeGranularityChanged}
              options={TIME_GRANULARITIES}
            />
          </div>
        </div>
        <Line
          data={formattedData}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </div>
    );
  }
}

export default withStyles(s)(GlobalHitRateLineChart);
