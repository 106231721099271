import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'cardWidget.title',
    defaultMessage: 'Persönliche Empfehlungen',
    description: 'label for no more recommendations message',
  },
  progressBarInfo: {
    id: 'cardWidget.progressBarInfo',
    defaultMessage:
      '* Der blaue Balken zeigt an, wie gut wir deine Empfehlungen an dich angepasst haben.',
    description: 'label for liked items message',
  },
  infoLink: {
    id: 'cardWidget.infoLink',
    defaultMessage: '(Wie funktioniert das?)',
    description: 'label for disliked items message',
  },
  resetPersonalization: {
    id: 'cardWidget.resetPersonalization',
    defaultMessage: 'Personalisierung zurücksetzen',
    description: 'label for wishlist message',
  },
});

export default messages;
