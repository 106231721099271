import Login from './login';
import LandingPage from './landingPage';
import ProductList from './productList';
import ProductPage from './productPage';
import AnalyticsDashboard from './analyticsDashboard';
import MyLists from './myLists';

const routes = [
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/dashboard',
    component: AnalyticsDashboard,
    exact: true,
  },
  {
    path: '/my-lists',
    component: MyLists,
    exact: true,
  },
  {
    path: '/:category',
    component: ProductList,
    exact: true,
  },
  {
    path: '/:category/product/:id',
    component: ProductPage,
    exact: true,
  },
];

export default routes;
