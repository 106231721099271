import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import gql from 'graphql-tag';

import baseStyles from '../../styles/app.scss';
import s from './Layout.css';
import TopNav from '../TopNav';
import Sidebar from '../Sidebar';

export const getCategoriesQuery = gql`
  query getCategories {
    getCategories {
      categories
    }
  }
`;

class Layout extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingCategories: true,
      categories: [],
    };

    this.loadCategories = this.loadCategories.bind(this);
  }

  componentDidMount() {
    this.loadCategories();
  }

  async loadCategories() {
    try {
      this.setState({ loadingCategories: true });

      const result = await this.context.client.query({
        query: getCategoriesQuery,
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getCategories) {
        if (result.data.getCategories.errors) {
          this.setState({
            loadingCategories: false,
            categories: null,
          });
        } else {
          this.setState({
            loadingCategories: false,
            categories: result.data.getCategories.categories || [],
          });
        }
      }
    } catch (e) {
      this.setState({
        loadingCategories: false,
        categories: null,
      });
    }
  }

  render() {
    const { loadingCategories, categories } = this.state;

    return (
      <Container fluid style={{ height: '100%' }}>
        <Row>
          <Col>
            <TopNav categories={loadingCategories ? [] : categories || []} />
          </Col>
        </Row>
        <Row style={{ height: '100%', flexWrap: 'nowrap' }}>
          <Col style={{ minHeight: '100%', paddingBottom: '15px' }}>
            <Sidebar categories={loadingCategories ? [] : categories || []} />
          </Col>
          <Col style={{ flexGrow: 9 }}>{this.props.children}</Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(baseStyles, s)(Layout);
