exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".L0B2h{margin-bottom:20px}._1bs-o{position:fixed;max-height:50%;overflow:auto;border:1px solid #dfdfdf;background:hsla(0,0%,100%,.95);z-index:1000}._2cVG1{padding:2px 4px}._2cVG1:hover{cursor:pointer}._19C8V{width:100%;min-width:500px;padding:5px;font-size:17px;border:1px solid #dfdfdf;border-top-left-radius:5px;border-bottom-left-radius:5px}._33uJs{padding:5px 10px;color:#fff;font-size:17px;border:1px solid #115699;border-left:none;border-top-right-radius:5px;border-bottom-right-radius:5px;background:#115699;cursor:pointer}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"searchForm": "L0B2h",
	"menu": "_1bs-o",
	"menuItem": "_2cVG1",
	"searchInput": "_19C8V",
	"searchButton": "_33uJs"
};