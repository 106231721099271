import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Breadcrumbs.scss';

const Breadcrumbs = ({ path }) => (
  <div className={s.breadcrumbs}>
    {path.map((entry, index) => {
      if (index === path.length - 1) {
        return <span>{entry.label}</span>;
      }
      return [
        <Link key={`${entry.url}_link`} to={entry.url}>
          <span>{entry.label}</span>
        </Link>,
        <FaChevronRight key={`${entry.url}_chevron`} />,
      ];
    })}
  </div>
);

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default withStyles(s)(Breadcrumbs);
