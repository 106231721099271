const newCategoryNames = {
  bmkubafsy: 'Abfallsysteme',
  hausgakkus: 'Akkusauger',
  hallesschn: 'Allesschneider',
  hhhggras: 'Aschesauger',
  hifistream: 'Audio-Streaming',
  hherdback: 'Backöfen & Dampfgarer',
  bmswzbr: 'Baustellenradios',
  bmgwzmsboha: 'Bodenhacken',
  hstaub: 'Bodenstaubsauger',
  wbohr: 'Bohrmaschinen',
  hbrotback: 'Brotbackautomaten',
  hbuegel: 'Bügeleisen',
  hausgbues: 'Bügelstationen',
  hificdp: 'CD-Player',
  hdampfrein: 'Dampfreiniger',
  dcam: 'Digitalkameras',
  diktier: 'Diktiergeräte',
  hdunst: 'Dunstabzugshauben',
  heierko: 'Eierkocher',
  hherd60: 'Einbauherde',
  hsegrill: 'Elektrogriller',
  hkoerper_her: 'Elektrorasierer',
  hkoerper_zahn: 'Elektrozahnbürsten',
  hifiend: 'Endstufen',
  hentsft: 'Entsafter & Pressen',
  hkoerper_dam: 'Epilierer',
  hhhgfs: 'Fenstersauger',
  hkbfbsh: 'Festbrennstoffherde',
  phonw: 'Festnetz',
  hgesufthm: 'Fieberthermometer',
  hkaffee: 'Filtermaschinen mit Glaskanne',
  hkaffeeth: 'Filtermaschinen mit Thermoskanne',
  hfritteuse: 'Fritteusen',
  wfraesen: 'Fräsen',
  garkettzub: 'Gartenmaschinen Zubehör',
  gscher: 'Gartenscheren',
  hgefrtruh: 'Gefriertruhen',
  hhgspzb: 'Geschirrspülzubehör',
  hkererhgttr: 'Haarglätter',
  hkoerper_haar: 'Haarschneider & Trimmer',
  hkoerper_ht: 'Haartrockner',
  hhleuchthalo: 'Halogenlampen',
  whobel: 'Handgeführte Hobel',
  wsl: 'Handgeführte Schleifer',
  wsag: 'Handgeführte Sägen',
  hkliwaz: 'Heizen & Kühl Zubehör',
  hheizung: 'Heizgeräte',
  drohungsstavkel: 'Heißluftgeräte',
  garhochd: 'Hochdruckreiniger',
  garhaex: 'Häcksler',
  kabeladapt: 'Kabel & Adapter',
  hkaffeez: 'Kaffee & Tee Zubehör',
  hkaffeemueh: 'Kaffeemühlen',
  hvollauto: 'Kaffeevollautomaten Haushalt',
  hpadsys: 'Kapsel-/Pad-Systeme',
  bmhwkrws: 'Kehrer & Wischer',
  bmgwzmkm: 'Kehrmaschinen',
  hkleinkue: 'Kleinküchen',
  hkochf: 'Kochfelder',
  hificom: 'Kompaktanlagen',
  hhleucht: 'Kompaktleuchtstofflampen',
  wkompres: 'Kompressoren',
  bmkubzb: 'Küche & Bad Zubehör',
  hkuechenmas: 'Küchenmaschinen',
  hkuewaagen: 'Küchenwaagen',
  hhleuchtled: 'LED-Lampen',
  garlaub: 'Laubsauger/-bläser',
  hluft: 'Lufthygiene',
  mmp: 'MP3-/MP4-Player',
  bmewzwzs: 'Maschinensets',
  hkoerper_mass: 'Massage & Akupunktur',
  wmeissel: 'Meißelhämmer',
  wmess: 'Messtechnik',
  hmicrow: 'Mikrowellen',
  hkaffeemas: 'Milchaufschäumer',
  hmixer: 'Mixer',
  phonmdg: 'Mobilfunk-Modems/-Router',
  hklimam: 'Monoblock-Klimageräte',
  garketten: 'Motorsägen',
  multi: 'Multifunktionsgeräte',
  bmewzmfw: 'Multifunktionswerkzeuge',
  hifimltlt: 'Multiroom-Lautsprecher',
  hkostlyhtsles: 'Multistyler & Sets',
  hausgnasstr: 'Nass- & Trockensauger',
  hnaehmach: 'Nähmaschinen',
  acamobjo: 'Objektive',
  djptylst: 'Partylautsprecher',
  hkoerper_waage: 'Personenwaagen',
  htischgrill: 'Platten- & Kontaktgriller',
  hifipho: 'Plattenspieler',
  spwapzb: 'Pool Zubehör',
  werkzgpesn: 'Pressen',
  garpump: 'Pumpen',
  hifirad: 'Radios & CD-Player',
  bmgwrasmrotr: 'Rasenmähroboter',
  gartrimm: 'Rasentrimmer',
  hrasiererzub: 'Rasurzubehör',
  bmhstegrgsm: 'Reinigungsmaschinen',
  hausgrobot: 'Reinigungsroboter',
  werkzgstgerw: 'Rührwerke',
  hhgtsf: 'Schneefräsen',
  wschneid: 'Schneidegeräte',
  hsiebtr: 'Siebträger',
  bmgbwad: 'Sonstige Gartenbewässerung',
  hrasrpfegshtreng: 'Sonstige Haarentfernung',
  hwinsntgke: 'Sonstige Kühlschränke',
  dcamz: 'Sonstiges Foto-/Videozubehör',
  hhkgklsg: 'Sonstiges Geschirr',
  hsgrillzb: 'Sonstiges Grillzubehör',
  hkleinzub: 'Sonstiges Zubehör für Küchengeräte',
  sm_sdhc: 'Speicherkarten',
  bmgbwse: 'Spindelmäher',
  hklimams: 'Split-Klimageräte',
  bmgbwsp: 'Sprüher & Regner',
  hkspuel: 'Küchenspülen',
  hstandherd60: 'Standherde',
  whbelos: 'Stationäre Hobel',
  wslektoslfe: 'Stationäre Schleifer',
  bmhwzsc: 'Staubsaugerzubehör',
  bmswzse: 'Stromerzeuger',
  hifisubw: 'Subwoofer',
  hifisur: 'Surroundsysteme',
  wtacker: 'Tacker/Nagler',
  sm_usb: 'USB-Sticks',
  hgrossvkuums: 'Vakuumier- & Zubehörschubladen',
  hvent: 'Ventilatoren',
  hifiamp: 'Verstärker',
  gartvert: 'Vertikutierer',
  hifipre: 'Vorstufen',
  wlanroutmod: 'WLAN-Router mit Modem',
  wlanrout: 'WLAN-Router',
  hwakoecher: 'Wasserkocher',
  hwein: 'Weinkühlschränke',
  hifiwiar: 'Wireless Audio-Receiver',
  hkoerper_waerme: 'Wärme- & Lichttherapie',
  hgrosswschl: 'Wärmeschubladen',
};

export default newCategoryNames;
