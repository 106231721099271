exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2UELs{max-width:500px}._2UELs ._2ok4S{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#fff;background-color:#115699;border-radius:5px}._2UELs ._2ok4S svg{margin-right:4px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"errors": "_2UELs",
	"button": "_2ok4S"
};