exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DIYH{max-width:600px}.yZKQI{font-size:18px;text-align:center}._2_ROW h3{margin:15px 0 5px;font-size:16px;font-weight:400}._2_ROW h3:first-child{margin-top:0}", ""]);

// exports
exports.locals = {
	"tagsModal": "_3DIYH",
	"tagsModalTitle": "yZKQI",
	"tagsModalBody": "_2_ROW"
};