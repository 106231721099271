exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2mcBT{max-width:600px}.FV07u{font-size:18px;text-align:center}._2V_Dc{font-size:16px}._2V_Dc ._3UWvl{color:#0db5ff}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"buyModal": "_2mcBT",
	"buyModalTitle": "FV07u",
	"buyModalBody": "_2V_Dc",
	"productSpan": "_3UWvl"
};