exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yqmA{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;text-align:center}._3IdiW,._3yqmA{width:100%;height:100%}._3IdiW ._3zu80{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:0 5px}._3IdiW ._3zu80 img{max-width:100%;max-height:100%;margin:0 auto}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"noImagesContainer": "_3yqmA",
	"imagesListContainer": "_3IdiW",
	"imageSlide": "_3zu80"
};