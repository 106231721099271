import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaRefresh from 'react-icons/lib/fa/refresh';
import FaHome from 'react-icons/lib/fa/home';
import FaList from 'react-icons/lib/fa/list';

import s from './Sidebar.scss';
import categoryNames from '../../util/productCategories';
import newCategoryNames from '../../util/newProductCategories';
import danubeFish from '../../assets/fishy_blue_horizontal.png';

const allCategoryNames = { ...categoryNames, ...newCategoryNames };

const reloadRules = async () => {
  const res = await fetch('/api/v1/develop/reload-rules');

  if (res.status === 200) {
    window.location.reload();
  }
};

export const getNavLinks = ({ location, categories }) => [
  <Link
    key="/dashboard"
    to="/dashboard"
    className={`nav-link ${
      location.pathname === '/dashboard' ? 'nav-link-active' : ''
    }`}
  >
    <FaHome /> Dashboard
  </Link>,
  <Link
    key="/my-lists"
    to="/my-lists"
    className={`nav-link ${
      location.pathname === '/my-lists' ? 'nav-link-active' : ''
    }`}
  >
    <FaList /> Meine Listen
  </Link>,
  <hr key="hr" />,
  categories.map(category => (
    <a
      key={`/${category}`}
      href={`/${category}`}
      className={`nav-link ${
        location.pathname === `/${category}` ? 'nav-link-active' : ''
      } ${newCategoryNames[category] != null ? s.newCategory : ''}`}
    >
      {allCategoryNames[category] || category}
    </a>
  )),
];

const Sidebar = ({ location, categories }) => (
  <div className={s.sidebar}>
    <Nav className="flex-column">{getNavLinks({ location, categories })}</Nav>
    <hr />
    <span className={s.poweredBy}>
      powered by&nbsp;
      <a
        href="https://danube.ai"
        title="danube.ai"
        target="_blank"
        rel="noopener noreferrer"
      >
        danube.ai
      </a>
      <img src={danubeFish} alt="danube.ai" />
    </span>
    <div className={s.reloadWrapper}>
      <button onClick={reloadRules}>
        <FaRefresh />
      </button>
    </div>
  </div>
);

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withRouter(withStyles(s)(Sidebar));
