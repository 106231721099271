import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import FaRepeat from 'react-icons/lib/fa/repeat';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Error.scss';

const Error = ({ errors }) => (
  <div className={s.errors} key="errors">
    <h3>Error</h3>
    {errors.map((error, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Alert key={`error-${index}`} variant="danger">
        {error}
      </Alert>
    ))}
    <button
      className={`btn btn-default ${s.button}`}
      onClick={() => {
        window.location.reload();
      }}
    >
      <FaRepeat />
      Try again
    </button>
  </div>
);

Error.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(s)(Error);
