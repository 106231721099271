/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-bootstrap';

import s from './BuyModal.scss';

class BuyModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    product: PropTypes.string.isRequired,
  };

  render() {
    const { show, onClose, product } = this.props;

    return (
      <Modal show={show} onHide={onClose} dialogClassName={s.buyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className={s.buyModalTitle}>
            Kauf erfolgreich
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={s.buyModalBody}>
          Sie haben <span className={s.productSpan}>{product}</span> erfolgreich
          gekauft. Der Betrag wird in Kürze von Ihrem Bankkonto abgebucht...
          Oder auch nicht, da dies nur eine Demo ist.
        </Modal.Body>
      </Modal>
    );
  }
}

export default withStyles(s)(BuyModal);
