/* eslint-disable react/sort-comp */
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';
import FaAngleRight from 'react-icons/lib/fa/angle-right';
import FaAngleDoubleLeft from 'react-icons/lib/fa/angle-double-left';
import FaAngleDoubleRight from 'react-icons/lib/fa/angle-double-right';
import FaStar from 'react-icons/lib/fa/star';
import FaStarHalfEmpty from 'react-icons/lib/fa/star-half-empty';
import FaStarEmpty from 'react-icons/lib/fa/star-o';
import FaList from 'react-icons/lib/fa/list';
import FaChecked from 'react-icons/lib/fa/check-square-o';
import FaEmpty from 'react-icons/lib/fa/square-o';

import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import CategoryListsWrapper from '../LocalStorage/CategoryListsWrapper';
import Loading from '../Loading';
import s from './ProductList.scss';
import SearchForm from './SearchForm';
import DanubeTags from '../DanubeTags';
import Spinner from '../Spinner';
import UpsellingProductsList from './UpsellingProductsList';
import Error from '../Error';
import { getCategoriesQuery } from '../Layout';

const SHOW_NUMERIC_DANUBE_SCORES = false;

export const renderStars = (rating, maximumRating) => {
  const normalizedRating = rating / maximumRating;

  const ratingNormalizedToTen = Math.round(normalizedRating * 10);
  const ratingNormalizedToFive = ratingNormalizedToTen / 2;

  const fullStarCount = Math.floor(ratingNormalizedToFive);
  const halfStarCount = Math.ceil(ratingNormalizedToFive) - Math.floor(ratingNormalizedToFive); // eslint-disable-line prettier/prettier
  const emptyStarCount = 5 - Math.ceil(ratingNormalizedToFive);

  const stars = [];
  for (let i = 0; i < fullStarCount; i += 1) {
    stars.push(<FaStar key={`star-${i}`} />);
  }
  for (let i = 0; i < halfStarCount; i += 1) {
    stars.push(<FaStarHalfEmpty key={`halfStar-${i}`} />);
  }
  for (let i = 0; i < emptyStarCount; i += 1) {
    stars.push(<FaStarEmpty key={`emptyStar-${i}`} />);
  }

  return (
    <div className={s.stars} title={rating}>
      {stars}
    </div>
  );
};

export const renderInactiveStars = count => {
  const stars = [];
  for (let i = 0; i < count; i += 1) {
    stars.push(<FaStarEmpty key={`star-${i}`} className={s.inactiveStar} />);
  }

  return <div className={s.stars}>{stars}</div>;
};

export const getRuleSetQuery = gql`
  query getRuleSet($category: String!) {
    getRuleSet(category: $category) {
      data
    }
  }
`;

const categoryProductsQuery = gql`
  query categoryProducts($category: String!) {
    categoryProducts(category: $category) {
      total
      count
      default_currency
      products {
        id
        product
        image_thumb
        description {
          prop
          value
        }
        best_price
        ppu
        offer_count
        rating_comments
        rating_count
        rating_percent
        rating_stars
        timestamp
      }
    }
  }
`;

const sortProductsWithDanubeQuery = gql`
  query sortProductsWithDanube($category: String!) {
    sortProductsWithDanube(category: $category) {
      sortedProducts
      productScores
      productMatches
      columnKeys
      columnScores
    }
  }
`;

export const findBestUpSellingProductsQuery = gql`
  query findBestUpSellingProducts(
    $referenceProductId: Int
    $referenceProductIds: [Int]
    $category: String!
    $filter: ProductsFilter
    $columnBoost: ColumnBoost
    $stripUnimportantFields: Boolean
    $stripColumnScores: Boolean
  ) {
    findBestUpSellingProducts(
      referenceProductId: $referenceProductId
      referenceProductIds: $referenceProductIds
      category: $category
      filter: $filter
      columnBoost: $columnBoost
      stripUnimportantFields: $stripUnimportantFields
      stripColumnScores: $stripColumnScores
    ) {
      referenceProduct {
        product {
          id
          product
          image_thumb
          description {
            prop
            value
          }
          best_price
          ppu
          offer_count
          rating_comments
          rating_count
          rating_percent
          rating_stars
          timestamp
        }
        danubeProduct {
          fields {
            field
            value
          }
        }
      }
      referenceProducts {
        product {
          id
          product
          image_thumb
          description {
            prop
            value
          }
          best_price
          ppu
          offer_count
          rating_comments
          rating_count
          rating_percent
          rating_stars
          timestamp
        }
        danubeProduct {
          fields {
            field
            value
          }
        }
      }
      products {
        product {
          id
          product
          image_thumb
          description {
            prop
            value
          }
          best_price
          ppu
          offer_count
          rating_comments
          rating_count
          rating_percent
          rating_stars
          timestamp
        }
        danubeProduct {
          fields {
            field
            value
          }
        }
        betterIn
        worseIn
        betterIns {
          prop
          value
        }
        worseIns {
          prop
          value
        }
      }
      columnKeys
      columnScores
    }
  }
`;

const productPerformanceQuery = gql`
  query productPerformanceQuery($productId: Int!) {
    productPerformance(productId: $productId) {
      totalHitrate
    }
  }
`;

export const increaseClickCountMutation = gql`
  mutation increaseClickCount(
    $referenceProductId: Int!
    $upsellingProductId: Int!
  ) {
    increaseClickCount(
      referenceProductId: $referenceProductId
      upsellingProductId: $upsellingProductId
    )
  }
`;

export const increaseHitrateMutation = gql`
  mutation increaseHitrate(
    $referenceProductId: Int!
    $upsellingProductId: Int!
  ) {
    increaseHitrate(
      referenceProductId: $referenceProductId
      upsellingProductId: $upsellingProductId
    )
  }
`;

/* eslint-disable no-param-reassign */
export const onStarHover = (thisRef, starIndex, productIndex) => {
  if (
    thisRef.hoveredStar !== starIndex ||
    thisRef.hoveredProductIndex !== productIndex
  ) {
    thisRef.hoveredProductIndex = productIndex;
    thisRef.hoveredStar = starIndex;

    thisRef.setState({
      hoveredProductIndex: productIndex,
      hoveredStar: starIndex,
    });
  }
};

export const onStarLeave = (thisRef, starIndex, productIndex) => {
  if (
    thisRef.hoveredStar === starIndex &&
    thisRef.hoveredProductIndex === productIndex
  ) {
    thisRef.hoveredProductIndex = -1;
    thisRef.hoveredStar = -1;

    thisRef.setState({
      hoveredProductIndex: -1,
      hoveredStar: -1,
    });
  }
};
/* eslint-enable no-param-reassign */

class ProductList extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        category: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    categoryLists: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    addItemToCategoryLists: PropTypes.func.isRequired,
    removeItemFromCategoryLists: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingCategories: true,
      categories: null,
      loadingRuleSet: true,
      ruleSet: null,
      loadingCategoryProducts: false,
      categoryProducts: null,
      waitingForDanubeSorting: false,
      autoCompleteItems: [],
      danubeResults: {},
      defaultOrder: {},
      maxDanubeScore: 0,
      columnKeys: [],
      columnScores: [],
      sortedProducts: null,
      errors: [],
      currentPage: 0,
      productsPerPage: 10,
      productPage: null,
      upsellingProductsPage: [],
      productPerformancesPage: [],
      expandedEntries: {},
      searchValue: '',
      sortProperty: null,
      sortOrder: 'desc',
      hoveredProductIndex: -1, // eslint-disable-line react/no-unused-state
      hoveredStar: -1, // eslint-disable-line react/no-unused-state
    };

    this.loadRuleSet = this.loadRuleSet.bind(this);
    this.loadCategoryProducts = this.loadCategoryProducts.bind(this);
    this.sortProductsWithDanube = this.sortProductsWithDanube.bind(this);
    this.findBestUpSellingProduct = this.findBestUpSellingProduct.bind(this);
    this.fetchUpsellingProductsForCurrentPage = this.fetchUpsellingProductsForCurrentPage.bind(this); // eslint-disable-line prettier/prettier
    this.getProductPerformance = this.getProductPerformance.bind(this);
    this.fetchProductPerformancesForCurrentPage = this.fetchProductPerformancesForCurrentPage.bind(this); // eslint-disable-line prettier/prettier
    this.getDanubeScoreById = this.getDanubeScoreById.bind(this);
    this.getDanubeMatchesById = this.getDanubeMatchesById.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.getMaxPage = this.getMaxPage.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.filterAndSortProducts = this.filterAndSortProducts.bind(this);
    this.setCurrentProductsPage = this.setCurrentProductsPage.bind(this);
    this.handleUpSellingClicked = this.handleUpSellingClicked.bind(this);
    this.renderNavigationButtons = this.renderNavigationButtons.bind(this);
    this.renderSortButton = this.renderSortButton.bind(this);
    this.renderTableHeaders = this.renderTableHeaders.bind(this);
    this.renderTableLine = this.renderTableLine.bind(this);
    this.renderUpSellingProductCell = this.renderUpSellingProductCell.bind(this); // eslint-disable-line prettier/prettier
    this.renderProductPerformanceCell = this.renderProductPerformanceCell.bind(this); // eslint-disable-line prettier/prettier
  }

  componentDidMount() {
    this.loadCategories();
  }

  async loadCategories() {
    try {
      this.setState({ loadingCategories: true });

      const result = await this.context.client.query({
        query: getCategoriesQuery,
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getCategories) {
        if (result.data.getCategories.errors) {
          this.setState({
            loadingCategories: false,
            categories: null,
          });
        } else {
          this.setState(
            {
              loadingCategories: false,
              categories: result.data.getCategories.categories || [],
            },
            () => {
              this.loadRuleSet();
            },
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingCategories: false,
        categories: null,
      });
    }
  }

  async loadRuleSet() {
    try {
      this.setState({ loadingRuleSet: true });

      const result = await this.context.client.query({
        query: getRuleSetQuery,
        variables: {
          category: this.props.match.params.category,
        },
        fetchPolicy: 'network-only',
      });

      if (result && result.data && result.data.getRuleSet) {
        if (result.data.getRuleSet.errors) {
          this.setState({
            loadingRuleSet: false,
            errors: [result.data.getRuleSet.errors],
          });
        } else {
          this.setState(
            {
              loadingRuleSet: false,
              ruleSet: JSON.parse(result.data.getRuleSet.data),
            },
            () => {
              if (this.state.ruleSet != null) {
                this.loadCategoryProducts();
              }
            },
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingRuleSet: false,
        errors: [e.message],
      });
    }
  }

  async loadCategoryProducts() {
    try {
      this.setState({ loadingCategoryProducts: true });

      const result = await this.context.client.query({
        query: categoryProductsQuery,
        variables: { category: this.props.match.params.category },
      });

      if (result && result.data && result.data.categoryProducts) {
        if (result.data.categoryProducts.errors) {
          this.setState({
            loadingCategoryProducts: false,
            errors: [result.data.categoryProducts.errors],
          });
        } else {
          delete result.data.categoryProducts.__typename;
          result.data.categoryProducts.products.forEach(p => {
            // eslint-disable-next-line no-param-reassign
            delete p.__typename;
            if (p.description) {
              p.description.forEach(d => {
                // eslint-disable-next-line no-param-reassign
                delete d.__typename;
              });
            }
          });

          let autoCompleteItems = [];
          if (result.data.categoryProducts.products) {
            autoCompleteItems = result.data.categoryProducts.products.map(
              p => ({
                id: p.id,
                label: p.product,
              }),
            );
          }

          this.setState(
            {
              loadingCategoryProducts: false,
              waitingForDanubeSorting: true,
              categoryProducts: result.data.categoryProducts,
              autoCompleteItems,
              errors: [],
            },
            this.sortProductsWithDanube,
          );
        }
      }
    } catch (e) {
      this.setState({
        loadingCategoryProducts: false,
        errors: [e.message],
      });
    }
  }

  async sortProductsWithDanube() {
    try {
      const result = await this.context.client.query({
        query: sortProductsWithDanubeQuery,
        variables: { category: this.props.match.params.category },
      });

      if (result.errors) {
        this.setState({
          waitingForDanubeSorting: false,
          errors: result.errors,
        });
        return;
      }

      const sortedProducts = result.data
        ? result.data.sortProductsWithDanube.sortedProducts
        : [];

      const productScores = result.data
        ? result.data.sortProductsWithDanube.productScores
        : [];

      const productMatches = result.data
        ? result.data.sortProductsWithDanube.productMatches
        : [];

      const danubeResults = {};

      for (let i = 0; i < sortedProducts.length; i += 1) {
        danubeResults[sortedProducts[i]] = {
          danubeScore: productScores[i],
          danubeMatches: productMatches[i],
        };
      }

      const defaultOrder = sortedProducts.reduce((map, id, index) => {
        // eslint-disable-next-line no-param-reassign
        map[id] = index;
        return map;
      }, {});

      const maxDanubeScore = Math.max(...productScores).toFixed(2);

      this.setState(
        {
          waitingForDanubeSorting: false,
          danubeResults,
          defaultOrder,
          maxDanubeScore,
          columnKeys: result.data.sortProductsWithDanube.columnKeys,
          columnScores: result.data.sortProductsWithDanube.columnScores,
        },
        this.filterAndSortProducts,
      );
    } catch (e) {
      this.setState({
        waitingForDanubeSorting: false,
        errors: [e.message],
      });
    }
  }

  async findBestUpSellingProduct(referenceProduct) {
    let result;

    try {
      result = await this.context.client.query({
        query: findBestUpSellingProductsQuery,
        variables: {
          referenceProductId: referenceProduct.id,
          category: this.props.match.params.category,
          stripUnimportantFields: false,
          stripColumnScores: false,
        },
      });
    } catch (e) {
      return {
        error: e.message,
      };
    }

    if (result && result.data && result.data.findBestUpSellingProducts) {
      return result.data.findBestUpSellingProducts.products;
    }

    return null;
  }

  async fetchUpsellingProductsForCurrentPage() {
    const jobId = Math.random();
    this.currentJobId = jobId;

    const { productPage } = this.state;

    const upsellingProductsPage = [];

    for (let i = 0; i < productPage.length; i += 1) {
      upsellingProductsPage.push({
        loading: true,
        products: [],
        error: null,
      });
    }

    this.setState({ upsellingProductsPage });

    for (let i = 0; i < productPage.length; i += 1) {
      const referenceProduct = { ...productPage[i] };
      delete referenceProduct.danubeScore;
      delete referenceProduct.danubeMatches;

      // eslint-disable-next-line no-await-in-loop
      const upsellingProducts = await this.findBestUpSellingProduct(
        referenceProduct,
      );

      if (this.currentJobId !== jobId) {
        return;
      }

      upsellingProductsPage[i] = {
        loading: false,
        products:
          upsellingProducts && !upsellingProducts.error
            ? upsellingProducts
            : null,
        error:
          upsellingProducts && upsellingProducts.error
            ? upsellingProducts.error
            : null,
      };

      this.setState({ upsellingProductsPage });
    }
  }

  async getProductPerformance(product) {
    const result = await this.context.client.query({
      query: productPerformanceQuery,
      variables: {
        productId: product.id,
      },
    });

    if (result && result.data && result.data.productPerformance) {
      return result.data.productPerformance.totalHitrate;
    }

    return null;
  }

  async fetchProductPerformancesForCurrentPage() {
    const jobId = Math.random();
    this.currentProductPerformancesJobId = jobId;

    const { productPage } = this.state;

    const productPerformancesPage = [];

    for (let i = 0; i < productPage.length; i += 1) {
      productPerformancesPage.push({
        loading: true,
        hitrate: null,
      });
    }

    this.setState({ productPerformancesPage });

    for (let i = 0; i < productPage.length; i += 1) {
      const product = { ...productPage[i] };
      delete product.danubeScore;
      delete product.danubeMatches;

      // eslint-disable-next-line no-await-in-loop
      const hitrate = await this.getProductPerformance(product);

      if (this.currentProductPerformancesJobId !== jobId) {
        return;
      }

      productPerformancesPage[i] = {
        loading: false,
        hitrate,
      };

      this.setState({ productPerformancesPage });
    }
  }

  getDanubeScoreById(productId) {
    const productResults = this.state.danubeResults[productId];
    if (productResults) return productResults.danubeScore.toFixed(2);
    return null;
  }

  getDanubeMatchesById(productId) {
    const productResults = this.state.danubeResults[productId];
    if (productResults) return productResults.danubeMatches;
    return null;
  }

  prevPage({ step = 1 } = {}) {
    const { currentPage } = this.state;
    const prevPage = Math.max(0, currentPage - step);

    this.setState(
      {
        currentPage: prevPage,
        expandedEntries: {},
      },
      this.setCurrentProductsPage,
    );
  }

  nextPage({ step = 1 } = {}) {
    const { sortedProducts, currentPage } = this.state;
    const nextPage =
      sortedProducts && sortedProducts.length > 0
        ? Math.min(this.getMaxPage(), currentPage + step)
        : currentPage;

    this.setState(
      {
        currentPage: nextPage,
        expandedEntries: {},
      },
      this.setCurrentProductsPage,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getMaxPage() {
    const { sortedProducts, productsPerPage } = this.state;
    return Math.ceil(sortedProducts.length / productsPerPage) - 1;
  }

  searchProducts(searchValue) {
    this.setState({ searchValue }, this.filterAndSortProducts);
  }

  setSortProperty(property) {
    const { sortProperty, sortOrder } = this.state;

    if (sortProperty === property) {
      this.setState(
        {
          sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
        },
        this.filterAndSortProducts,
      );
    } else {
      this.setState(
        {
          sortProperty: property,
          sortOrder: 'asc',
        },
        this.filterAndSortProducts,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  filterAndSortProducts() {
    const {
      categoryProducts,
      defaultOrder,
      searchValue,
      sortProperty,
      sortOrder,
    } = this.state;

    if (!categoryProducts || !categoryProducts.products) return;

    // filter by search values
    let newSortedProducts = categoryProducts.products.filter(p =>
      p.product.toLowerCase().includes(searchValue.toLowerCase()),
    );

    // map danube scores
    newSortedProducts = newSortedProducts.map(p => ({
      ...p,
      danubeScore: this.getDanubeScoreById(p.id),
      danubeMatches: this.getDanubeMatchesById(p.id),
    }));

    // sort products
    if (sortProperty != null) {
      newSortedProducts = newSortedProducts.sort((p1, p2) => {
        let p1Value = p1[sortProperty];
        let p2Value = p2[sortProperty];

        if (p1Value == null && p2Value == null) {
          return 0;
        } else if (p1Value == null) {
          return sortOrder === 'asc' ? -1 : 1;
        } else if (p2Value == null) {
          return sortOrder === 'asc' ? 1 : -1;
        }

        if (typeof p1Value === 'string' || p1Value instanceof String) {
          p1Value = p1Value.toLowerCase();
        }
        if (typeof p2Value === 'string' || p2Value instanceof String) {
          p2Value = p2Value.toLowerCase();
        }

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(p1Value) && !isNaN(p2Value)) {
          p1Value = parseFloat(p1Value);
          p2Value = parseFloat(p2Value);
        }

        if (p1Value > p2Value) {
          return sortOrder === 'asc' ? 1 : -1;
        } else if (p2Value > p1Value) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        return 0;
      });
    } else {
      newSortedProducts = newSortedProducts.sort(
        (p1, p2) => defaultOrder[p1.id] - defaultOrder[p2.id],
      );
    }

    const { currentPage, productsPerPage } = this.state;
    const newCurrentPage =
      newSortedProducts.length > 0
        ? Math.min(
            Math.ceil(newSortedProducts.length / productsPerPage) - 1,
            currentPage,
          )
        : currentPage;

    this.setState(
      {
        sortedProducts: newSortedProducts,
        currentPage: newCurrentPage,
        expandedEntries: {},
      },
      this.setCurrentProductsPage,
    );
  }

  setCurrentProductsPage() {
    const { sortedProducts, currentPage, productsPerPage } = this.state;

    let productPage = null;
    if (sortedProducts) {
      const from = Math.min(
        currentPage * productsPerPage,
        sortedProducts.length - 1,
      );
      const to = Math.min(
        (currentPage + 1) * productsPerPage,
        sortedProducts.length,
      );
      productPage = sortedProducts.slice(from, to);
    }

    this.setState({ productPage }, () => {
      this.fetchUpsellingProductsForCurrentPage();
      this.fetchProductPerformancesForCurrentPage();
    });
  }

  handleUpSellingClicked(product, upsellingProduct) {
    this.context.client.mutate({
      mutation: increaseClickCountMutation,
      variables: {
        referenceProductId: product.id,
        upsellingProductId: upsellingProduct.id,
      },
    });
  }

  renderNavigationButtons() {
    const { currentPage, productPage } = this.state;
    return (
      <div className={s.buttonBar}>
        <button
          className={s.prevButton}
          onClick={() => this.prevPage({ step: 10 })}
        >
          <FaAngleDoubleLeft />
        </button>
        <button className={s.prevButton} onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <span style={{ position: 'relative', top: '1px', margin: '0 10px' }}>
          {currentPage + 1} / {productPage ? this.getMaxPage() + 1 : 1}
        </span>
        <button className={s.nextButton} onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button
          className={s.nextButton}
          onClick={() => this.nextPage({ step: 10 })}
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  renderSortButton(text, property) {
    const { sortProperty, sortOrder } = this.state;

    return (
      <button
        className={s.sortButton}
        onClick={() => {
          this.setSortProperty(property);
        }}
      >
        {text}
        {sortProperty === property && (
          <span>{sortOrder === 'asc' ? <FaCaretDown /> : <FaCaretUp />}</span>
        )}
      </button>
    );
  }

  renderTableHeaders() {
    const { sortedProducts } = this.state;

    return (
      <div className={s.tableHeader}>
        <div className={s.likeColumn}>
          <FaList />
        </div>
        <div className={s.imageColumn}>Bild</div>
        <div className={s.descriptionColumn}>
          {this.renderSortButton(
            `${sortedProducts ? sortedProducts.length || 0 : 0} Produkte`,
            'product',
          )}
        </div>
        <div className={s.ratingColumn}>
          {this.renderSortButton('Bewertung\n(User)', 'rating_stars')}
        </div>
        <div className={s.priceColumn}>
          {this.renderSortButton('Preis', 'best_price')}
        </div>
        <div className={s.danubeColumn}>
          {this.renderSortButton('Preis-/\nLeistungswertung', 'danubeScore')}
        </div>
        <div className={s.hitrateColumn}>
          {this.renderSortButton('UP%', '__hitrate__')}
        </div>
        <div className={s.upsellingColumn}>
          Ähnliches oder besseres
          <br />
          Preis-/Leistungsverhältnis
        </div>
      </div>
    );
  }

  renderTableLine(index, product, upsellingProducts, productPerformance) {
    const {
      categoryLists,
      addItemToCategoryLists,
      removeItemFromCategoryLists,
      match: {
        params: { category },
      },
    } = this.props;

    const {
      ruleSet,
      categoryProducts,
      expandedEntries,
      maxDanubeScore,
      columnKeys,
    } = this.state;

    const lineClass =
      (index + 1) % 2 === 0
        ? `${s.tableLine} ${s.oddRow}`
        : `${s.tableLine} ${s.evenRow}`;

    const descriptionString = [];
    const filteredDescriptions = product.description
      ? product.description.filter(
          d =>
            ruleSet == null ||
            ruleSet.rules.find(r => r.from === d.prop || r.property === d.prop),
        )
      : [];
    filteredDescriptions.forEach((d, i) => {
      descriptionString.push(
        <span key={d.prop}>
          <b>{d.prop}:</b> {d.value}
        </span>,
      );
      if (i < filteredDescriptions.length - 1) {
        // eslint-disable-next-line react/no-array-index-key
        descriptionString.push(<br key={`br-${i}`} />);
      }
    });

    const descriptionClass =
      expandedEntries[product.id] === true
        ? s.descriptionExpanded
        : s.description;

    const bestTags = [];
    if (product.danubeMatches) {
      product.danubeMatches.forEach((match, i) => {
        if (
          match === 1 &&
          columnKeys[i] !== 'Gelistet seit' &&
          columnKeys[i] !== 'Nutzerbewertung'
        ) {
          bestTags.push(columnKeys[i]);
        }
      });
    }

    const isChecked =
      categoryLists[category] && categoryLists[category].includes(product.id);

    return (
      <div key={product.id} className={lineClass}>
        <div className={s.likeColumn}>
          <button
            onClick={() => {
              if (isChecked) {
                removeItemFromCategoryLists({ category, itemId: product.id });
              } else {
                addItemToCategoryLists({ category, itemId: product.id });
              }
            }}
          >
            {isChecked ? <FaChecked /> : <FaEmpty />}
          </button>
        </div>
        <div className={s.imageColumn}>
          <img src={product.image_thumb} alt="" />
        </div>
        <div className={s.descriptionColumn}>
          <Link
            to={`/${this.props.match.params.category}/product/${product.id}`}
          >
            <b>{product.product}</b>
          </Link>
          {bestTags.length > 0 && (
            <div className={s.bestTagsContainer}>
              <span>Bestes Produkt in: </span>
              {bestTags.map(bestTag => (
                <div className={s.tag} key={bestTag}>
                  {bestTag}
                </div>
              ))}
            </div>
          )}
          <div className={descriptionClass}>{descriptionString}</div>
          <button
            className={s.expandButton}
            onClick={() => {
              const expanded = !expandedEntries[product.id];
              this.setState({
                expandedEntries: {
                  ...expandedEntries,
                  [product.id]: expanded,
                },
              });
            }}
          >
            {expandedEntries[product.id] === true ? (
              <FaCaretUp />
            ) : (
              <FaCaretDown />
            )}
          </button>
        </div>
        <div className={s.ratingColumn}>
          {product.rating_stars ? renderStars(product.rating_stars, 5) : null}
          {product.rating_count
            ? `${product.rating_count} Bewertungen`
            : '(zu wenige)'}
        </div>
        <div className={`${s.priceColumn} ${s.priceColumnContent}`}>
          {'ab '}
          {categoryProducts.default_currency === 'EUR'
            ? '€'
            : categoryProducts.default_currency}
          {` ${product.best_price.toFixed(0)}`}
        </div>
        <div className={s.danubeColumn}>
          {renderStars(product.danubeScore, maxDanubeScore)}
          {SHOW_NUMERIC_DANUBE_SCORES &&
            `(Danube Score: ${product.danubeScore})`}
        </div>
        <div className={s.hitrateColumn}>
          {this.renderProductPerformanceCell(productPerformance)}
        </div>
        <div className={s.upsellingColumn}>
          {this.renderUpSellingProductCell(product, upsellingProducts)}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderUpSellingProductCell(product, upsellingProducts) {
    if (!upsellingProducts) return '';

    if (upsellingProducts.error) return upsellingProducts.error;

    return (
      <UpsellingProductsList
        loading={upsellingProducts.loading}
        category={this.props.match.params.category}
        upsellingProducts={upsellingProducts.products}
        onUpsellingProductClicked={upsellingProduct => {
          this.handleUpSellingClicked(product, upsellingProduct);
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderProductPerformanceCell(productPerformance) {
    if (productPerformance && productPerformance.loading) {
      return <Spinner show margin="0" fontSize="4px" />;
    }
    if (
      !productPerformance ||
      (!productPerformance.hitrate && productPerformance.hitrate !== 0)
    ) {
      return <span>-</span>;
    }
    return <span>{(productPerformance.hitrate * 100).toFixed(2)}</span>;
  }

  render() {
    const {
      loadingCategories,
      categories,
      loadingRuleSet,
      ruleSet,
      loadingCategoryProducts,
      waitingForDanubeSorting,
      autoCompleteItems,
      errors,
      productPage,
      upsellingProductsPage,
      productPerformancesPage,
      columnKeys,
      columnScores,
    } = this.state;

    if (!loadingCategories) {
      if (
        categories &&
        !categories.includes(this.props.match.params.category)
      ) {
        return <div>Invalid category</div>;
      }
    }

    if (!loadingRuleSet && ruleSet == null) {
      return <div>Invalid category</div>;
    }

    return (
      <>
        {errors.length === 0 && (
          <>
            <div key="productListPage" className={s.productListPage}>
              <div className={s.searchFormWrapper}>
                <SearchForm
                  searchItems={autoCompleteItems}
                  onSearch={this.searchProducts}
                />
              </div>
              <DanubeTags columnKeys={columnKeys} columnScores={columnScores} />
              {this.renderNavigationButtons()}
              <div className={s.productsTableWrapper}>
                <div className={s.productsTable}>
                  {this.renderTableHeaders()}
                  {productPage &&
                    productPage.map((p, index) =>
                      this.renderTableLine(
                        index,
                        p,
                        upsellingProductsPage[index],
                        productPerformancesPage[index],
                      ),
                    )}
                </div>
              </div>
              {this.renderNavigationButtons()}
            </div>
          </>
        )}
        {errors.length > 0 && <Error errors={errors} />}
        {(loadingCategories ||
          loadingRuleSet ||
          loadingCategoryProducts ||
          waitingForDanubeSorting) && <Loading key="loading" />}
      </>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  CategoryListsWrapper,
  withStyles(s),
)(ProductList);
