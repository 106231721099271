import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Card.scss';
import categoryNames from '../../util/productCategories';
import newCategoryNames from '../../util/newProductCategories';

const allCategoryNames = { ...categoryNames, ...newCategoryNames };

class Card extends React.Component {
  static propTypes = {
    itemData: PropTypes.shape({
      id: PropTypes.number.isRequired,
      product: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      best_price: PropTypes.number,
      images: PropTypes.array.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {};

    this.cardRef = React.createRef();
    this.lastCardSize = -1;

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // initial resize call
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    if (
      this.cardRef &&
      this.cardRef.current &&
      this.lastCardSize !== this.cardRef.current.offsetWidth
    ) {
      this.lastCardSize = this.cardRef.current.offsetWidth;
    }
  }

  render() {
    const { itemData } = this.props;
    const { product: title, category, best_price: price, images } = itemData;

    const titleContent = (
      <div className={`${s.titleContent} ${s.line}`}>
        {itemData.product != null && <div className={s.title}>{title}</div>}
        {itemData.category != null ? (
          <div className={s.category}>
            {allCategoryNames[category] || category}
          </div>
        ) : (
          <div className={s.line} />
        )}
        {itemData.product != null && <div className={s.price}>€ {price}</div>}
      </div>
    );
    return (
      <a
        href={`/${category}/product/${itemData.id}`}
        onClick={this.props.onClick}
      >
        <div className={`${s.card}`} ref={this.cardRef}>
          <div className={`${s.cardContent}`}>
            <div className={s.imageContainer}>
              {images && images.length !== 0 ? (
                <img src={images[0]} alt="" />
              ) : (
                <div className={s.loadingImage}>
                  <div className={s.loading} />
                </div>
              )}
            </div>
            <div className={s.titleContainer}>{titleContent}</div>
          </div>
        </div>
      </a>
    );
  }
}

export default compose(withStyles(s))(Card);
