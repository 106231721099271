/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ReactAutocomplete from 'react-autocomplete';
import FaSearch from 'react-icons/lib/fa/search';

import s from './SearchForm.scss';

class SearchForm extends React.Component {
  static propTypes = {
    searchItems: PropTypes.arrayOf(PropTypes.object),
    onSearch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchItems: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };
  }

  render() {
    const { searchItems, onSearch } = this.props;

    return (
      <div className={s.searchForm}>
        <ReactAutocomplete
          items={searchItems}
          getItemValue={item => item.label}
          shouldItemRender={(item, value) =>
            item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          renderItem={(item, highlighted) => (
            <div
              key={item.id}
              className={s.menuItem}
              style={{
                backgroundColor: highlighted ? '#eee' : 'transparent',
              }}
            >
              {item.label}
            </div>
          )}
          value={this.state.searchValue}
          onChange={e => {
            this.setState({ searchValue: e.target.value });
          }}
          onSelect={value => {
            this.setState({ searchValue: value });
          }}
          renderInput={props => <input className={s.searchInput} {...props} />}
          renderMenu={(items, value, style) => (
            <div
              className={s.menu}
              style={{
                ...style,
              }}
              // eslint-disable-next-line react/no-children-prop
              children={items}
            />
          )}
        />
        <button
          className={s.searchButton}
          onClick={() => onSearch(this.state.searchValue)}
        >
          <FaSearch style={{ position: 'relative', top: '-1px' }} />
        </button>
      </div>
    );
  }
}

export default withStyles(s)(SearchForm);
