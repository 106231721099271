import React from 'react';

import {
  getCategoryLists,
  addItemToCategoryLists,
  removeItemFromCategoryLists,
} from '../../util/localStorageUtils';

const CategoryListsWrapper = WrappedComponent => {
  class WithCategoryLists extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        categoryLists: {},
      };

      this.refetchCategorsLists = this.refetchCategorsLists.bind(this);
      this.addItemToCategoryLists = this.addItemToCategoryLists.bind(this);
      this.removeItemFromCategoryLists = this.removeItemFromCategoryLists.bind(
        this,
      );
    }

    componentDidMount() {
      this.refetchCategorsLists();
    }

    refetchCategorsLists() {
      this.setState({
        categoryLists: getCategoryLists(),
      });
    }

    addItemToCategoryLists({ category, itemId }) {
      addItemToCategoryLists({ category, itemId });
      this.refetchCategorsLists();
    }

    removeItemFromCategoryLists({ category, itemId }) {
      removeItemFromCategoryLists({ category, itemId });
      this.refetchCategorsLists();
    }

    render() {
      const { categoryLists } = this.state;

      return (
        <WrappedComponent
          categoryLists={categoryLists}
          addItemToCategoryLists={this.addItemToCategoryLists}
          removeItemFromCategoryLists={this.removeItemFromCategoryLists}
          {...this.props}
        />
      );
    }
  }

  return WithCategoryLists;
};

export default CategoryListsWrapper;
