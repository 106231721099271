import React from 'react';

import AnalyticsDashboard from 'components/AnalyticsDashboard';

class AnalyticsDashboardWrapper extends React.Component {
  render() {
    return <AnalyticsDashboard />;
  }
}

export default AnalyticsDashboardWrapper;
