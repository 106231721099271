const categoryNames = {
  umtsover: 'Mobile Phones',
  tvlcd: 'TVs',
  hdssd: 'SSD Karten',
  hwaschf: 'Waschmachinen',
  uhrpm: 'Sportuhren',
  nb: 'Notebooks',
  spradelec: 'E-Bikes',
  sprspsbb: 'E-Roller',
  spradcomput: 'Fahrradcomputer',
  wsr: 'Schrauber',
  rasm: 'Rasenmäher',
  hsggrill: 'Gasgriller',
  hifipaspkr: 'Portable Lautsprecher',
  hifibox: 'Lautsprecher',
  sphd: 'Kopfhörer',
  nbtabl: 'Tablets',
  spokbx: 'Kühlboxen',
  monlcd19wide: 'Monitore',
  spoutruck: 'Rucksäcke',
  spbekleidung: 'Jacken',
  dcamsp: 'Systemkameras',
  sphomet: 'Hometrainer',
  spglo: 'Handschuhe',
  spoutnrgpl: 'Sportnahrung',
  spbergschuh: 'Bergschuhe',
  spradschuhe: 'Fahrradschuhe',
  hkuehlsch: 'Kühlschränke',
  hgefr: 'Gefrierschränke',
  hkuegef: 'Kühl-/Gefrierkombinationen',
  spoutmesser: 'Taschenmesser',
  hgeschirr60: 'Geschirrspüler',
  wsageksgn: 'Sägen stationär',
  spfitstsn: 'Sporttaschen',
  hwaeschtr: 'Trockner',
  spoutlauf: 'Laufschuhe',
  hwaschtr: 'Waschtrockner',
  spzgmodlbbdr: 'Drohnen',
  spoutlampen: 'Portable Lampen',
  spradtrink: 'Trinkflaschen',
  sphosen: 'Hosen',
  spoutroller: 'Roller',
  spradbekleidung: 'Fahrradtrikots',
  spcrosst: 'Crosstrainer',
  spgolftroll: 'Golftrolleys',
  beam: 'Beamer',
};

export default categoryNames;
