exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._8FxKH{position:relative;width:100%;height:100%}._8FxKH ._33rDE{position:absolute;top:calc(50% - 17.5px);width:35px;height:35px;padding:5px;color:#000;background:none;border:none;outline:none;-webkit-box-shadow:none;box-shadow:none;font-size:20px;z-index:1000}._8FxKH ._33rDE:disabled{cursor:not-allowed;color:#b4b4b4}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"horizontalListContainer": "_8FxKH",
	"slideNavigationButton": "_33rDE"
};