const categories = [
  'umtsover',
  'tvlcd',
  'hdssd',
  'hwaschf',
  'uhrpm',
  'nb',
  'spradelec',
  'sprspsbb',
  'spradcomput',
  'wsr',
  'rasm',
  'hsggrill',
  'hifipaspkr',
  'hifibox',
  'sphd',
  'nbtabl',
  'spokbx',
  'monlcd19wide',
  'spoutruck',
  'spbekleidung',
  'dcamsp',
  'spoutnrgpl',
  'spglo',
  'sphomet',
  'spbergschuh',
  'spradschuhe',
  'hkuehlsch',
  'hgefr',
  'hkuegef',
  'spoutmesser',
  'hgeschirr60',
  'wsageksgn',
  'spfitstsn',
  'hwaeschtr',
  'spoutlauf',
  'hwaschtr',
  'spzgmodlbbdr',
  'spoutlampen',
  'spradtrink',
  'sphosen',
  'spoutroller',
  'spradbekleidung',
  'spcrosst',
  'spgolftroll',
  'beam',
];

export default categories;
