/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';
import Cookies from 'js-cookie';

import gql from 'graphql-tag';

import s from './CardRecommendationWidget.scss';
import messages from './messages';
import Card from '../RecommenderCard';
import EmptyCard from '../RecommenderCard/EmptyCard';
import SkeletonCard from '../RecommenderCard/SkeletonCard';
import MetricProgressBar from '../MetricProgressBar';

import categories from './queryHelpers';
import { MAX_RECOMMENDATION_ITEMS } from '../../constants';

const CLICKS_UNTIL_FULL_METRIC = 11;

function setUserMetricCookie({ userMetric }) {
  Cookies.set('user_metric', userMetric, { expires: 7 });
}

function getUserMetricCookie() {
  const userMetric = Cookies.get('user_metric');
  return userMetric || 0;
}

function getRandom(list) {
  return list[Math.floor(Math.random() * list.length)];
}

const randomItemsQuery = gql`
  query randomItems($categories: [String]!) {
    randomItems(categories: $categories) {
      items {
        id
        product
        images
        best_price
        category
      }
    }
  }
`;

class CardRecommendationWidget extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recommendationItems: null,
      userClicks: 0,
    };

    this.renderCards = this.renderCards.bind(this);
    this.loadProductData = this.loadProductData.bind(this);
    this.loadUserMetric = this.loadUserMetric.bind(this);
    this.writeUserMetric = this.writeUserMetric.bind(this);
    this.calculateUserMetric = this.calculateUserMetric.bind(this);
    this.resetUserMetric = this.resetUserMetric.bind(this);
  }

  async componentDidMount() {
    await this.loadProductData();
    this.loadUserMetric();
  }

  loadUserMetric() {
    const cookieUserMetric = getUserMetricCookie();
    const cookieUserMetricNumber = Math.floor(parseInt(cookieUserMetric, 10));
    this.setState({
      userClicks: cookieUserMetricNumber,
    });
  }

  writeUserMetric(input) {
    setUserMetricCookie({ userMetric: this.state.userClicks + 1 });
  }

  calculateUserMetric() {
    const { userClicks } = this.state;
    if (userClicks > CLICKS_UNTIL_FULL_METRIC) {
      return 100;
    }
    return Math.floor((userClicks * 100) / CLICKS_UNTIL_FULL_METRIC);
  }

  resetUserMetric() {
    setUserMetricCookie({ userMetric: 0 });
    this.loadUserMetric();
  }

  async loadProductData() {
    this.setState({ loading: true });
    const randomCategories = [];
    let randomItems = [];
    for (let i = 0; i < MAX_RECOMMENDATION_ITEMS; i += 1) {
      randomCategories.push(getRandom(categories || []));
    }
    const result = await this.context.client.query({
      query: randomItemsQuery,
      variables: {
        categories: randomCategories,
      },
      fetchPolicy: 'network-only',
    });
    if (result && result.data && result.data.randomItems) {
      randomItems = result.data.randomItems.items || [];
    }

    this.setState({
      loading: false,
      recommendationItems: randomItems,
    });
  }

  renderCards() {
    const { loading, recommendationItems } = this.state;
    // get recommended cards
    const cards = [];

    if (loading) {
      for (let i = 0; i < MAX_RECOMMENDATION_ITEMS; i += 1) {
        cards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <SkeletonCard />
          </div>,
        );
      }
    }

    if (recommendationItems && recommendationItems.length > 0) {
      for (let i = 0; i < MAX_RECOMMENDATION_ITEMS; i += 1) {
        cards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <Card
              key={`card-${i}`}
              itemData={recommendationItems[i]}
              onClick={this.writeUserMetric}
            />
          </div>,
        );
      }
    }

    if (cards.length === 0) {
      const emptyCards = [];
      emptyCards.push(
        <div className={s.cardWrapper} key={`empty-card}`}>
          <EmptyCard />
        </div>,
      );
      return emptyCards;
    }

    return cards;
  }

  render() {
    return (
      <div className={s.widgetContainer}>
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>
        <div className={s.widgetInner}>
          <div className={s.widgetCrown}>
            <div className={s.danubeSignifier}>
              <a
                href="https://danube.ai"
                title="danube.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/danube_universal_signifier.svg" alt="" />{' '}
              </a>
            </div>
            <div className={s.progressBar}>
              <MetricProgressBar userMetric={this.calculateUserMetric()} />
            </div>
          </div>
          <div className={s.cardsContainer}>{this.renderCards()}</div>
          <div key="infoLine" className={s.infoLine}>
            <div>
              <span>
                <FormattedMessage {...messages.progressBarInfo} />{' '}
              </span>
              <a
                href="https://danube.ai/technology"
                title="danube.ai technologe page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={s.underline}>
                  <FormattedMessage {...messages.infoLink} />
                </span>
              </a>
            </div>
            <div
              className={`${s.resetButton}`}
              role="button"
              onKeyDown={this.resetUserMetric}
              onClick={this.resetUserMetric}
              tabIndex={0}
            >
              <FormattedMessage {...messages.resetPersonalization} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(s))(CardRecommendationWidget);
