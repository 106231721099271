/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from 'react-slick';
import FaArrowLeft from 'react-icons/lib/fa/arrow-left';
import FaArrowRight from 'react-icons/lib/fa/arrow-right';

import s from './HorizontalList.scss';

const NavigationArrow = props => {
  const { className, onClick, disabled } = props;
  return (
    <button
      className={`${className} ${s.slideNavigationButton}`}
      onClick={onClick}
      disabled={disabled}
    >
      {props.icon}
    </button>
  );
};
NavigationArrow.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};
NavigationArrow.defaultProps = {
  onClick: () => {},
};

const PrevArrow = props => (
  <NavigationArrow
    className={props.className}
    icon={<FaArrowLeft />}
    onClick={props.onClick}
    disabled={props.currentSlide === 0}
  />
);
PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
};
PrevArrow.defaultProps = {
  className: null,
  onClick: () => {},
  currentSlide: null,
};

const NextArrow = props => (
  <NavigationArrow
    className={props.className}
    icon={<FaArrowRight />}
    onClick={props.onClick}
    disabled={props.currentSlide === props.slideCount - props.slidesToShow}
  />
);
NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  slidesToShow: PropTypes.number,
};
NextArrow.defaultProps = {
  className: null,
  onClick: () => {},
  currentSlide: null,
  slideCount: null,
  slidesToShow: 1,
};

class HorizontalList extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
    renderItem: PropTypes.func.isRequired,
    slideWidth: PropTypes.number,
    slideMinHeight: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
  };

  static defaultProps = {
    items: [],
    slideWidth: null,
    slideMinHeight: null,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
    };

    this.getSliderSettings = this.getSliderSettings.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.indexChanged = this.indexChanged.bind(this);
  }

  getSliderSettings() {
    const { slidesToShow, slidesToScroll } = this.props;

    return {
      dots: false,
      infinite: false,
      speed: 500,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slidesToShow={slidesToShow} />,
      slidesToShow,
      slidesToScroll,
    };
  }

  prevSlide() {
    const { currentIndex } = this.state;

    this.indexChanged(Math.max(0, currentIndex - 1));
  }

  nextSlide() {
    const { items } = this.props;
    const { currentIndex } = this.state;

    this.indexChanged(Math.min(items.length - 1, currentIndex + 1));
  }

  indexChanged(newIndex) {
    this.setState({
      currentIndex: newIndex,
    });
  }

  render() {
    const { items, renderItem, slideMinHeight } = this.props;

    const additionalStyles = { style: {}, slideStyle: {} };

    if (slideMinHeight != null) {
      additionalStyles.slideStyle.minHeight = `${slideMinHeight}px`;
    }

    return (
      <div className={s.horizontalListContainer}>
        <Slider {...this.getSliderSettings()}>
          {items.map(item => renderItem(item))}
        </Slider>
      </div>
    );
  }
}

export default withStyles(s)(HorizontalList);
