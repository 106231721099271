import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './MetricProgressBar.scss';

class MetricProgressBar extends React.Component {
  static propTypes = {
    userMetric: PropTypes.number.isRequired,
  };

  render() {
    const { userMetric } = this.props;
    const labelColor = 'rgb(13, 181, 255)';

    return (
      <div className={s.userMetricsprogressBarContainer}>
        <div
          className={s.userMetricsProgressBarFilled}
          style={{ width: `${userMetric}%` }}
        >
          <div className={s.whiteBackgroundHelper} />
          <div className={s.userMetricsLabelWrapper}>
            <div
              className={s.userMetricsLabel}
              style={{ backgroundColor: `${labelColor}` }}
            >
              {userMetric}%
            </div>
          </div>
        </div>
        <div className={s.userMetricsProgressBarNotFilled}>
          <div className={s.backgroundHelper} />
          <div className={s.userMetricsProgressBar} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(MetricProgressBar);
