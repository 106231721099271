exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1HSQd{margin:5px 0}._1HSQd a{color:rgba(0,0,0,.5)}._1HSQd a:active,._1HSQd a:focus,._1HSQd a:hover{color:#000}._1HSQd svg{color:rgba(0,0,0,.5);width:10px;margin:0 5px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"breadcrumbs": "_1HSQd"
};