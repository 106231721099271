import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Card.scss';

class SkeletonCard extends React.Component {
  render() {
    return (
      <div className={s.skeletonCard}>
        <div className={s.cardContent}>
          <div className={s.loading} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SkeletonCard);
