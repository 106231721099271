/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaArrowUp from 'react-icons/lib/fa/arrow-up';
import FaArrowDown from 'react-icons/lib/fa/arrow-down';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line css-modules/no-unused-class
import s from './UpsellingProductsList.scss';
import Spinner from '../Spinner';
import HorizontalList from '../HorizontalList';
import BetterAndWorseTagsModal from './BetterAndWorseTagsModal';
import { danubeTagsMap } from '../DanubeTags';

class UpsellingProductsList extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    category: PropTypes.string.isRequired,
    upsellingProducts: PropTypes.arrayOf(PropTypes.object),
    onUpsellingProductClicked: PropTypes.func.isRequired,
    slideWidth: PropTypes.number,
    slideMinHeight: PropTypes.number,
    slidePadding: PropTypes.number,
    slideBackgroundColor: PropTypes.string,
    slidesToShow: PropTypes.number,
  };

  static defaultProps = {
    loading: true,
    upsellingProducts: [],
    slideWidth: null,
    slideMinHeight: null,
    slidePadding: 0,
    slideBackgroundColor: 'transparent',
    slidesToShow: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTagsModal: false,
      tagsModalData: {
        title: '',
        betterIn: [],
        worseIn: [],
      },
    };

    this.renderSlide = this.renderSlide.bind(this);
  }

  renderSlide(upsellingProductData) {
    const { slidePadding, slideBackgroundColor } = this.props;
    const { product, betterIn, worseIn } = upsellingProductData;

    let betterTags = [];
    if (betterIn.length === 1 && betterIn[0] === 'recommendationScore') {
      betterTags = [
        <div key="recommendationScore" className={s.betterTag}>
          <FaArrowUp />
          <span>Größere Beliebtheit</span>
        </div>,
      ];
    } else {
      betterTags = betterIn
        .filter(property => property !== 'recommendationScore')
        .map(property => (
          <div key={property} className={s.betterTag}>
            <FaArrowUp />
            {danubeTagsMap[property] ? (
              <FormattedMessage {...danubeTagsMap[property]} />
            ) : (
              <span>{property}</span>
            )}
          </div>
        ));
    }

    const worseTags = worseIn.map(property => (
      <div key={property} className={s.worseTag}>
        <FaArrowDown />
        {danubeTagsMap[property] ? (
          <FormattedMessage {...danubeTagsMap[property]} />
        ) : (
          <span>{property}</span>
        )}
      </div>
    ));

    return (
      <div key={product.id} className={s.upsellingProductSlide}>
        <div
          className={s.upsellingProductSlideContent}
          style={{
            padding: `${slidePadding}px`,
            backgroundColor: slideBackgroundColor,
          }}
        >
          <a
            href={`/${this.props.category}/product/${product.id}`}
            onClick={() => {
              this.props.onUpsellingProductClicked(product);
            }}
          >
            <img src={product.image_thumb} alt="" />
            <b>{product.product}</b>
          </a>
          {betterTags.length + worseTags.length > 0 && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
              key="betterTags"
              className={s.betterAndWorseTagsContainer}
              onClick={() => {
                this.setState({
                  showTagsModal: true,
                  tagsModalData: {
                    title: product.product,
                    betterIn,
                    worseIn,
                  },
                });
              }}
            >
              {betterTags.slice(0, 3)}
              {betterTags.length > 3 && (
                <div key="better..." className={s.betterTag}>
                  <FaArrowUp />
                  <span>...</span>
                </div>
              )}
              {worseTags.slice(0, 3)}
              {worseTags.length > 3 && (
                <div key="worse..." className={s.worseTag}>
                  <FaArrowDown />
                  <span>...</span>
                </div>
              )}
            </div>
          )}
          <span key="price" className={s.upsellingPrice}>
            &euro; {product.best_price}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      loading,
      upsellingProducts,
      slideWidth,
      slideMinHeight,
      slidesToShow,
    } = this.props;

    const { showTagsModal, tagsModalData } = this.state;

    if (loading) {
      return (
        <div className={s.loadingContainer}>
          <Spinner show margin="0" fontSize="4px" />
        </div>
      );
    }

    if (upsellingProducts == null || upsellingProducts.length === 0) {
      return (
        <div className={s.loadingContainer}>
          Dies ist bereits das beste vergleichbare Produkt!
        </div>
      );
    }

    return [
      <HorizontalList
        key="upsellingList"
        items={upsellingProducts}
        renderItem={this.renderSlide}
        slideWidth={slideWidth}
        slideMinHeight={slideMinHeight}
        slidesToShow={slidesToShow}
      />,
      <BetterAndWorseTagsModal
        key="tagsModal"
        show={showTagsModal}
        onClose={() => {
          this.setState({ showTagsModal: false });
        }}
        {...tagsModalData}
      />,
    ];
  }
}

export default withStyles(s)(UpsellingProductsList);
