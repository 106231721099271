import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FaHome from 'react-icons/lib/fa/home';
import FaList from 'react-icons/lib/fa/list';

import { withLogout } from '../Login/withLogout';

class TopNav extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  render() {
    const { location, logout } = this.props;

    return (
      <Navbar collapseOnSelect expand="lg" className="geizhals-top-nav">
        <Link to="/">
          <Navbar.Brand>E-commerce Demo</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Link
              key="/dashboard"
              to="/dashboard"
              className={`nav-link ${
                location.pathname === '/dashboard' ? 'nav-link-active' : ''
              }`}
            >
              <FaHome /> Dashboard
            </Link>
            <Link
              key="/my-lists"
              to="/my-lists"
              className={`nav-link ${
                location.pathname === '/my-lists' ? 'nav-link-active' : ''
              }`}
            >
              <FaList /> Meine Listen
            </Link>
          </Nav>
          <Nav className="mr-right">
            <button className="nav-link" onClick={logout}>
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(withLogout(TopNav));
