import React from 'react';

import ProductList from 'components/ProductList';

class ProductListWrapper extends React.Component {
  render() {
    return <ProductList />;
  }
}

export default ProductListWrapper;
